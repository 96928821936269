import { Box, ToggleButtonGroup, Typography } from "@mui/material";
import SidebarTree from "../sidebarTree/tree/SidebarTree";
import { useState } from "react";
import { ToggleButton } from "@brusnika.tech/ui-kit";
import { useGetTerritoriesQuery } from "../store/services/territories";
import { useNavigate } from "react-router-dom";
import { PATHS } from "../navigation/paths";

const Sidebar = () => {
  const [filteredTerritories, setFilteredTerritories] = useState("Все");
  const handleChangeValue = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: string
  ) => {
    setFilteredTerritories(newValue);
  };
  const { data: territories } = useGetTerritoriesQuery();
  const navigate = useNavigate();

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        flexDirection: "column",
        paddingTop: 4,
      }}
    >
      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <Box
          onClick={() => {
            navigate(PATHS.KRT);
          }}
        >
          <Typography sx={{ paddingLeft: 6, cursor: "pointer" }} fontSize={12}>
            Портфель
          </Typography>
        </Box>
        {territories && (
          <SidebarTree
            territories={
              filteredTerritories === "Все"
                ? territories.features
                : territories.features.filter(
                    (x) => x.properties.priority === filteredTerritories
                  )
            }
          />
        )}
      </Box>
      <Box sx={{ borderTop: 1, borderColor: "rgba(20, 25, 26, 0.12)" }}>
        <ToggleButtonGroup
          value={filteredTerritories}
          onChange={handleChangeValue}
          exclusive
          fullWidth
          sx={{
            padding: 6,
          }}
        >
          <ToggleButton value={"Все"} aria-label="left aligned">
            Все
          </ToggleButton>
          <ToggleButton value={"Регион присутствия"} aria-label="right aligned">
            РП
          </ToggleButton>
          <ToggleButton value={"I Приоритет"} aria-label="centered">
            I
          </ToggleButton>
          <ToggleButton value={"II Приоритет"} aria-label="right aligned">
            II
          </ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Box>
  );
};

export default Sidebar;
