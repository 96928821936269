export const nspdController = [
  {
    id: '1',
    label: 'МКД',
    background: 'rgba(255, 77, 0, 0.2)',
    borderColor: '#FF4D00',
    value: 'МКД',
    localValue: 'mkd',
    metaValue: 'НСПД_МКД',
  },
  {
    id: '2',
    label: 'ИЖД',
    background: 'rgba(3, 86, 246, 0.2)',
    borderColor: '#0356F6',
    value: 'ИЖС',
    localValue: 'igd',
    metaValue: 'НСПД_ИЖС',
  },
  {
    id: '3',
    label: 'Прочие',
    background: 'rgba(2, 96, 0, 0.2)',
    borderColor: '#026000',
    value: 'прочее',
    localValue: 'other',
    metaValue: 'НСПД_Прочие',
  },
];

export const izhsController = [
  {
    id: '1',
    label: '<50',
    background: '#8DD3FF',
    localValue: 'rangeUnder50',
    value: '0,50',
    metaValue: 'ИЖС_50',
  },
  {
    id: '2',
    label: '50-150',
    background: '#5487FF',
    localValue: 'range50to150',
    value: '50,150',
    metaValue: 'ИЖС_50_150',
  },
  {
    id: '3',
    label: '150-300',
    background: '#04119E',
    localValue: 'range150to300',
    value: '150,300',
    metaValue: 'ИЖС_150_300',
  },
  {
    id: '4',
    label: '>300',
    background: '#000860',
    localValue: 'rangeOver300',
    value: '300',
    metaValue: 'ИЖС_300',
  },
];

export const mingkhController = [
  {
    id: '2',
    label: 'Критериальные 1 эт',
    background: '#FFFF00',
    localValue: 'criteria1',
    value: 1,
    metaValue: 'МКД_Крит_1',
  },
  {
    id: '3',
    label: 'Критериальные 2 эт',
    background: '#FFB22C',
    localValue: 'criteria2',
    value: 2,
    metaValue: 'МКД_Крит_2',
  },
  {
    id: '4',
    label: 'Критериальные 3 эт',
    background: '#FF6415',
    localValue: 'criteria3',
    value: 3,
    metaValue: 'МКД_Крит_3',
  },
  {
    id: '5',
    label: 'Критериальные 4 эт',
    background: '#A56830',
    localValue: 'criteria4',
    value: 4,
    metaValue: 'МКД_Крит_4',
  },
];

export const oksOsmCotroller = [
  {
    id: '1',
    label: 'Все здания',
    localValue: 'all',
    value: 'value',
    background: 'rgba(123, 3, 166, 0.2)',
    borderColor: '#7B03A6',
  },
  // {
  //   id: '2',
  //   label: 'Все здания на ЗУ',
  //   value: 'allZu',
  // },
  // {
  //   id: '3',
  //   label: 'Все здания в терзонах',
  //   value: 'allInZone',
  // },
  // {
  //   id: '4',
  //   label: 'Все здания на ЗУ в подходящих зонах',
  //   value: 'allZuInZone',
  // },
];

export const nspdZoningCotroller = [
  {
    id: '1',
    label: 'А.1-Зона зеленых насаждений',
    background: '#1C8F69',
    value: 'a1',
  },
  {
    id: '2',
    label: 'А.2-Зона зеленых насаждений специального назначения',
    background: '#69B366',
    value: 'a2',
  },
  {
    id: '3',
    label: 'А.3-Зона защитных зеленых насаждений',
    background: '#C0E365',
    value: 'a3',
  },
  {
    id: '4',
    label: 'А.4-Зона парков, в т.ч. специализированного использования',
    background: '#00FFC5',
    value: 'a4',
  },
  //
  {
    id: '5',
    label: 'А.5-Зона скверов, бульваров, аллей',
    background: '#A6EBB4',
    value: 'a5',
  },
  {
    id: '6',
    label: 'А.6-Зона рекреационно-спортивная',
    background: '#45C355',
    value: 'a6',
  },
  {
    id: '7',
    label: 'А.7-Рекреационная зона интенсивного общественного использования',
    background: '#E2F8D2',
    value: 'a7',
  },
  {
    id: '8',
    label: 'А.8-Зона пойменных и прибрежных территорий',
    background: '#BEEE9C',
    value: 'a8',
  },
  {
    id: '9',
    label: 'А.9-Мемориальная рекреационная зона',
    background: '#2C6E01',
    value: 'a9',
  },
  {
    id: '10',
    label: 'А.10-Зона коллективных садов',
    background: '#DEF0B8',
    value: 'a10',
  },
  {
    id: '11',
    label: 'Б.1- Зона многофункциональной деловой застройки',
    background: '#A427A8',
    value: 'b1',
  },
  {
    id: '12',
    label: 'Б.2- Зона административных, торговых и развлекательных комплексов',
    background: '#AA0481',
    value: 'b2',
  },
  {
    id: '13',
    label: 'Б.3.1-Смешанная зона в зонах развития',
    background: '#EF7100',
    value: 'b31',
  },
  {
    id: '14',
    label: 'Б.3.2-Смешанная зона в зонах стабилизации',
    background: '#EF7100',
    value: 'b32',
  },
  {
    id: '15',
    label: 'Б.3.3-Смешанная зона реновации в зонах развития',
    background: '#EB4F0C',
    value: 'b33',
  },
  {
    id: '16',
    label: 'Б.3.4-Смешанная зона реновации в зонах стабилизации',
    background: '#EB4F0C',
    value: 'b34',
  },
  {
    id: '17',
    label: 'Б.4-Зона культурных и спортивных комплексов',
    background: '#C75E5C',
    value: 'b4',
  },
  {
    id: '18',
    label:
      'Б.4.1-Зона культурных и спортивных комплексов интенсивного озеленения',
    background: '#C05A8D',
    value: 'b41',
  },
  {
    id: '19',
    label: 'Б.5-Зона высших и средних профессиональных учебных учреждений',
    background: '#E29CDA',
    value: 'b5',
  },
  {
    id: '20',
    label: 'Б.5.1-Зона межуниверситетского кампуса',
    background: '#E29CDA',
    value: 'b51',
  },
  {
    id: '21',
    label: 'Б.6-Зона лечебно-профилактических учреждений',
    background: '#F0BCE7',
    value: 'b6',
  },
  {
    id: '22',
    label:
      'Б.6.1-Зона лечебно-профилактических учреждений интенсивного озеленения',
    background: '#CE77CA',
    value: 'b61',
  },

  {
    id: '23',
    label: 'Б.7.1-Зона исторического центра',
    background: '#AA3302',
    value: 'b71',
  },
  {
    id: '24',
    label: 'Б.7.2-Зона единой исторической застройки',
    background: '#700000',
    value: 'b72',
  },
  {
    id: '25',
    label: 'Б.8-Зона перспективной высотной застройки',
    background: '#EA4B0C',
    value: 'b8',
  },
  {
    id: '26',
    label: 'Б.9-Производственно-коммерческая зона развития',
    background: '#C06BAC',
    value: 'b9',
  },
  {
    id: '27',
    label:
      'В.1.1-Зона индивидуальной и блокированной жилой застройки в зонах развития',
    background: '#FFE132',
    value: 'v11',
  },
  {
    id: '28',
    label:
      'В.1.2-Зона индивидуальной и блокированной жилой застройки в зонах стабилизации',
    background: '#FFE132',
    value: 'v12',
  },
  {
    id: '29',
    label:
      'В.1.3-Зона индивидуальной жилой застройки, расположенной в зонах с особыми условиями',
    background: '#FFE132',
    value: 'v13',
  },
  {
    id: '30',
    label: 'В.1.4-Зона малоэтажной жилой застройки в зонах развития',
    background: '#FFAA00',
    value: 'v14',
  },
  {
    id: '31',
    label: 'В.1.5-Зона малоэтажной жилой застройки в зонах стабилизации',
    background: '#FFAA00',
    value: 'v15',
  },
  {
    id: '32',
    label: 'В.2.1-Зона среднеэтажной жилой застройки в зонах развития',
    background: '#FF5500',
    value: 'v21',
  },
  {
    id: '33',
    label: 'В.2.2-Зона среднеэтажной жилой застройки в зонах стабилизации',
    background: '#FF5500',
    value: 'v22',
  },
  {
    id: '34',
    label: 'В.3.1-Зона высотной жилой застройки в зонах развития',
    background: '#FF3232',
    value: 'v31',
  },
  {
    id: '35',
    label: 'В.3.2-Зона высотной жилой застройки в зонах стабилизации',
    background: '#FF3232',
    value: 'v32',
  },
  {
    id: '36',
    label: 'В.4-Зона образовательных и дошкольных учреждений',
    background: '#FFCCC4',
    value: 'v4',
  },
  {
    id: '37',
    label: 'Г.1-Зона производственно-складских объектов I-II классов опасности',
    background: '#BFBFBF',
    value: 'g1',
  },
  {
    id: '38',
    label: 'Г.2-Зона производственно-складских объектов III классов опасности',
    background: '#CCCCCC',
    value: 'g2',
  },
  {
    id: '39',
    label: 'Г.3-Зона производственно-складских объектов IV классов опасности',
    background: '#BFBFBF',
    value: 'g3',
  },
  {
    id: '40',
    label: 'Г.4-Зона производственно-складских объектов V классов опасности',
    background: '#E0E1E0',
    value: 'g4',
  },
  {
    id: '41',
    label: 'Г.5-Зона производственно-коммерческой деятельности',
    background: '#BAC8EC',
    value: 'g5',
  },
  {
    id: '42',
    label: 'Г.6-Зона коммунальных объектов',
    background: '#8DADDD',
    value: 'g6',
  },
  {
    id: '43',
    label: 'Г.7-Зона размещения автотранспорта',
    background: '#B5B7E9',
    value: 'g7',
  },
  {
    id: '44',
    label:
      'Г.8-Зона производственных территорий, предусмотренных для рекультивации',
    background: '#895A44',
    value: 'g8',
  },
  {
    id: '45',
    label: 'Д.1-Зона водозаборных сооружений',
    background: '#2CAFAD',
    value: 'd1',
  },
  {
    id: '46',
    label: 'Д.2-Зона кладбищ',
    background: '#305000',
    value: 'd2',
  },
  {
    id: '47',
    label: 'Д.3-Зона режимных объектов',
    background: '#FFE3CB',
    value: 'd3',
  },
  {
    id: '48',
    label: 'Д.4-Зона объектов ограниченного доступа',
    background: '#978100',
    value: 'd4',
  },
  {
    id: '49',
    label: 'К.1-Зоны внешнего транспорта (жел.дорого, авт. и воздуш. трансп.)',
    background: '#ABCEDF',
    value: 'k1',
  },
  {
    id: '50',
    label: 'К.2-Зона трубопроводов',
    background: '#FADFF5',
    value: 'k2',
  },
  {
    id: '51',
    label: 'К.3-Зона объектов дорожного сервиса',
    background: '#9EB2DA',
    value: 'k3',
  },
];

export const krtController = [
  {
    id: '1',
    label: 'Жилой застройки',
    background: 'rgba(15, 192, 0, 0.8)',
    value: 'residental',
  },
  {
    id: '2',
    label: 'Нежилой застройки',
    background: 'rgba(255, 232, 24, 0.8)',
    value: 'notResidental',
  },
  {
    id: '3',
    label: 'По инициативе правообладателя',
    background: 'rgba(0, 47, 215, 0.8)',
    value: 'initiative',
  },
  {
    id: '4',
    label: 'КРТ и РЗТ',
    background: 'rgba(183, 72, 75, 0.8)',
    value: 'krt',
  },
];
