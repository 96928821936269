import { createSlice } from '@reduxjs/toolkit';

type MapSettings = {
  mapStyle: string;
};

const initialState: MapSettings = {
  mapStyle: 'mapbox://styles/andreev1057/clw4wqp7902op01qu3q15fta5/draft',
};

const mapSettingsSlice = createSlice({
  name: 'mapSettingsSlice',
  initialState: initialState,
  reducers: {
    setMapStyle: (state, action) => {
      state.mapStyle = action.payload;
    },
  },
});

export const { setMapStyle } = mapSettingsSlice.actions;
export const { reducer: mapSettingsReducer } = mapSettingsSlice;
