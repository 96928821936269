import { createSlice } from '@reduxjs/toolkit';

type SelectedValue = {
  label: string;
  value: string;
  id: number;
};

type TableSettings = {
  searchValue: string;
  filterOptions: SelectedValue[];
};

const initialState: TableSettings = {
  searchValue: '',
  filterOptions: [],
};

const tableSettingsSlice = createSlice({
  name: 'tableSettingsSlice',
  initialState: initialState,
  reducers: {
    setSearchValue: (state, action) => {
      state.searchValue = action.payload;
    },
    setFilterOption: (state, action) => {
      state.filterOptions = action.payload;
    },
  },
});

export const { setSearchValue, setFilterOption } = tableSettingsSlice.actions;
export const { reducer: tableSettingsReducer } = tableSettingsSlice;
