import { CssBaseline, ThemeProvider } from '@mui/material';
import { theme } from '@brusnika.tech/ui-kit';
import { BrowserRouter } from 'react-router-dom';
import '@brusnika.tech/ui-kit/style.css';
import LayoutComponent from './components/layout/Layout';
import Router from './components/navigation/Router';
import AuthLayout from './components/authLayout/authLayout';
import { HelmetProvider } from 'react-helmet-async';

function App() {
  const helmetContext = {};
  return (
    <HelmetProvider context={helmetContext}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <BrowserRouter>
          <LayoutComponent>
            <AuthLayout>
              <Router />
            </AuthLayout>
          </LayoutComponent>
        </BrowserRouter>
      </ThemeProvider>
    </HelmetProvider>
  );
}

export default App;
