import { Button, IconButton, Status } from '@brusnika.tech/ui-kit';
import { Header, HeaderActions, useLayout } from '@brusnika.tech/ui-portal';
import { Stack, Typography } from '@mui/material';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useGetTerritoryKrtQuery,
  useGetTerritoryQuery,
} from '../store/services/territories';
import { useAppDispatch } from '../../hooks';
import { useEffect } from 'react';
import { setTerritory } from '../store/slices/territorySlice';
import { IconArrowLeft, IconSettings } from '@brusnika.tech/ui-icons';
import { useGetTradeByIdQuery } from '../store/services/trades';
import { SideSheetTestComponentProps } from '../layersSettings/types';
import LayersSettigsSideSheet from '../layersSettings/LayersSettings';

const MainHeader = () => {
  const dispatch = useAppDispatch();
  const { getSideSheetManager } = useLayout();
  const { id }: { id?: string } = useParams();
  const { card }: { card?: string } = useParams();
  const { krtId }: { krtId?: string } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const { data: territory, isSuccess } = useGetTerritoryQuery(
    {
      territoryId: id!,
    },
    { skip: !id },
  );

  const { data: soloTrade } = useGetTradeByIdQuery(
    {
      id: card,
    },
    { skip: !card },
  );

  const { data: krt, isSuccess: successKrt } = useGetTerritoryKrtQuery(
    {
      krtId: krtId!,
    },
    { skip: !krtId },
  );

  const getStatus = (status: string) => {
    if (status === 'Не состоялся') {
      return (
        <Status size="medium" variant="error">
          Не состоялся
        </Status>
      );
    } else if (status === 'Отменен') {
      return (
        <Status size="medium" variant="error">
          Отменен
        </Status>
      );
    } else if (status === 'Состоялся') {
      return (
        <Status size="medium" variant="success">
          Состоялся
        </Status>
      );
    } else if (status === 'Единственный участник') {
      return (
        <Status size="medium" variant="warning">
          Единственный участник
        </Status>
      );
    }
  };

  useEffect(() => {
    if (territory) {
      dispatch(setTerritory(territory));
    }
  }, [territory]);

  const handleLayersSettigsSideSheetShow = () => {
    const mySideSheetManager = getSideSheetManager<SideSheetTestComponentProps>(
      'layersSettigsSideSheet',
    );

    mySideSheetManager.show(
      LayersSettigsSideSheet,
      {
        onChange: () => {},
      },
      {
        useBackdrop: false,
        placement: 'right',
        toggleIfOpened: true,
        size: '320px',
        desktopContainerId: 'figura-layout-content-inner-container',
        mobileContainerId: 'figura-layout-content-container',
      },
    );
  };

  return (
    <Header
      heading={
        <Stack flexDirection="row" alignItems="center">
          {soloTrade?.subjectRF && card && (
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              style={{ marginRight: 8 }}
            >
              <IconArrowLeft iconSize="medium" />
            </IconButton>
          )}
          <Typography sx={{ marginRight: 3 }} fontSize={20} fontWeight={500}>
            {/* {id && territory && !soloTrade?.krtId && !krtId
              ? territory.properties.name
              : id && krtId
              ? `${territory?.properties.name} - ${krt?.code}`
              : card && soloTrade
              ? soloTrade?.krt?.code || soloTrade?.subjectRF
              : 'Портфель'} */}
            {id && territory && isSuccess && !krtId && !successKrt
              ? territory.properties.name
              : krt && isSuccess && territory && successKrt && !card
              ? `${territory.properties.name} - ${krt.code}`
              : card && soloTrade
              ? soloTrade?.krt?.code || soloTrade?.subjectRF
              : 'Портфель'}
          </Typography>
          {card && getStatus(soloTrade?.lotStatus)}
        </Stack>
      }
      actions={
        <HeaderActions>
          {id && territory?.properties.idPlatformISUPGround && (
            <Button
              onClick={() => {
                window.open(
                  `https://landbank.brusnika.ru/contours/${territory?.properties.idPlatformISUPGround}/registry/reserves-monitor`,
                );
              }}
              variant="outline"
              children="ИСУП Земли"
            />
          )}
          {id && location.pathname.includes('krt') && (
            <IconButton
              onClick={handleLayersSettigsSideSheetShow}
              variant="outline"
            >
              <IconSettings />
            </IconButton>
          )}
          {/* {id && location.pathname.includes('krt') && (
            <IconButton onClick={onClick}>
              <IconMeatball />
            </IconButton>
          )} */}
        </HeaderActions>
      }
    />
  );
};

export default MainHeader;
