import { createSlice } from '@reduxjs/toolkit';

type SelectedValue = {
  label: string;
  value: string;
  id: number;
  color: number[];
};

const initialState: SelectedValue[] = [
  // {
  //   label: '',
  //   value: '',
  //   id: 0,
  // },
];

const buildingLayer = createSlice({
  name: 'buildingLayer',
  initialState: initialState,
  reducers: {
    setBuildingLayer: (state, action) => {
      const existingIds = state.map((item) => item.id);
      const updatedState = state.filter((item) =>
        action.payload.some((newItem: SelectedValue) => newItem.id === item.id),
      );

      action.payload.forEach((newItem: SelectedValue) => {
        if (!existingIds.includes(newItem.id)) {
          updatedState.push(newItem);
        }
      });

      return updatedState;
    },
    updateBuildingLayer: (state, action) => {
      return action.payload;
    },
  },
});

export const { setBuildingLayer, updateBuildingLayer } = buildingLayer.actions;
export const { reducer: buildingLayerReducer } = buildingLayer;
