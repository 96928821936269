import {
  AuthenticationError,
  AxiosBFFAuth,
} from "@brusnika.tech/auth-bff-axios";
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

import EventEmitter from "events";

export interface UserInfo {
  userId: string;
  email: string;
  fullName: string;
}

type ResponseMapper<T, P = any> = (data: AxiosResponse<P>) => Promise<T> | T;

function handleResponse<R>(
  response: AxiosResponse<R>,
  responseMapper?: ResponseMapper<R>
): Promise<R> {
  if (responseMapper) {
    return Promise.resolve(responseMapper(response));
  } else {
    const contentType: string | undefined = response.headers["content-type"];
    if (contentType && contentType.includes("application/json")) {
      return Promise.resolve<R>(response.data);
    } else {
      throw new Error("Default error...");
    }
  }
}

const api: AxiosInstance = axios.create();
api.interceptors.request.use((rq) => ({ ...rq, withCredentials: true }));

export const authEvents: EventEmitter = new EventEmitter();
export const USER_UPDATE_EVENT: string = "userUpdate";
export const AUTH_ERROR_EVENT: string = "error";

export const auth: AxiosBFFAuth<UserInfo> = new AxiosBFFAuth<UserInfo>(api, {
  backendUrl: "https://service.brusnika.devcontour.ru",
  onUserUpdatedSuccess(user: UserInfo): void {
    authEvents.emit(USER_UPDATE_EVENT, user);
  },
  onUserUpdatedError(error, AuthenticationError): void {
    try {
      authEvents.emit(AUTH_ERROR_EVENT, error);
    } catch {}
  },
});

 
export async function apiFetch<T>(
  url: string,
  options: AxiosRequestConfig = {},
  responseMapper?: ResponseMapper<T>
): Promise<T | undefined> {
  try {
    const response: AxiosResponse<T> = await api.request({
      url: `https://service.brusnika.devcontour.ru${url}`,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ...options.headers,
      },
      ...options,
    });
    return handleResponse(response, responseMapper);
  } catch (error) {
    if (error instanceof Error && error.message.includes("isAxiosError is not a function")) {
      // Handle the error here if needed
      console.error("Error occurred: isAxiosError is not a function");
    } else {
      throw error;
    }
  }
}
