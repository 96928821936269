import { Krt } from '../../../types/krts';
import { Territories, Territory } from '../../../types/territories';
import { baseApi } from './baseApi';

export const territoriesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTerritories: builder.query<Territories, void>({
      query: () => '/territories',
      providesTags: ['Territories'],
    }),
    getTerritory: builder.query<Territory, { territoryId: string }>({
      query: ({ territoryId }) => `/territories/${territoryId}`,
      providesTags: ['Territories'],
    }),
    getTerritoryKrt: builder.query<Krt, { krtId: string }>({
      query: ({ krtId }) => `/krt/${krtId}`,
      providesTags: ['Territories'],
    }),
    getAllKrts: builder.query<any, void>({
      query: () => `/krt`,
      providesTags: ['Territories'],
    }),
  }),
});

export const {
  useGetTerritoriesQuery,
  useGetTerritoryQuery,
  useGetTerritoryKrtQuery,
  useGetAllKrtsQuery,
} = territoriesApi;
