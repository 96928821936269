import {
  IconArrowDown,
  IconArrowRight,
  IconEdit,
} from '@brusnika.tech/ui-icons';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@brusnika.tech/ui-kit';
import { Box, Collapse, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { izhsController } from '../../common/layersControllers';
import { useAppDispatch } from '../../hooks';
// import { setActiveLayer } from '../store/slices/layersSettingsSlice';
import { setIzhsLayer } from '../store/slices/tileLayerSlice';
import { setActiveLayer } from '../store/slices/activeLayersManager';
import CreateCustomIzhsLayer from '../modals/CreateCustomIzhsLayer';

const IzhsControl = () => {
  const dispatch = useAppDispatch();
  const [showLayers, setShowLayers] = useState(false);
  const [isAllLayersSelected, setIsAllLayersSelected] = useState(false);
  const [isOpenedDialog, setIsOpenedDialog] = useState(false);

  const [selectedLayers, setSelectedLayers] = useState<any>(() => {
    const storedLayers = localStorage.getItem('izhsLayers');
    return storedLayers
      ? JSON.parse(storedLayers)
      : {
          rangeUnder50: false,
          range50to150: false,
          range150to300: false,
          rangeOver300: false,
        };
  });

  useEffect(() => {
    setIsAllLayersSelected(
      Object.values(selectedLayers).some((value) => value),
    );
  }, [selectedLayers]);

  useEffect(() => {
    localStorage.setItem('izhsLayers', JSON.stringify(selectedLayers));
    izhsController.map((item) => {
      dispatch(
        setActiveLayer({
          name: 'ОКС ИЖД (ГИС ЖКХ)',
          layer: [
            {
              label: item.label,
              isActive: selectedLayers[item.localValue],
              value: item.value,
              id: +item.id,
              background: item.background,
              metaValue: item.metaValue,
            },
          ],
        }),
      );
      dispatch(
        setIzhsLayer({
          value: item.value,
          isActive: selectedLayers[item.localValue],
        }),
      );
    });
  }, [selectedLayers]);

  return (
    <>
      <CreateCustomIzhsLayer
        setIsOpenedDialog={() => {
          setIsOpenedDialog(false);
        }}
        isOpenedDialog={isOpenedDialog}
      />
      <Stack
        flexDirection={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}
      >
        <Stack flexDirection={'row'} alignItems={'center'}>
          <IconButton
            sx={{ width: 16, height: 16 }}
            onClick={() => {
              setShowLayers(!showLayers);
            }}
          >
            {showLayers ? (
              <IconArrowDown iconSize="medium" />
            ) : (
              <IconArrowRight iconSize="medium" />
            )}
          </IconButton>
          <FormControlLabel
            sx={{ paddingLeft: 2 }}
            control={
              <Checkbox
                checked={isAllLayersSelected}
                onChange={(e) => {
                  setSelectedLayers({
                    rangeUnder50: e.target.checked,
                    range50to150: e.target.checked,
                    range150to300: e.target.checked,
                    rangeOver300: e.target.checked,
                  });
                }}
              />
            }
            label={'ОКС ИЖД (ГИС ЖКХ)'}
          />
        </Stack>
        <IconButton
          onClick={() => {
            setIsOpenedDialog(true);
          }}
          size="small"
        >
          <IconEdit iconSize="small" sx={{ color: '#8A8C8C' }} />
        </IconButton>
      </Stack>
      <Collapse in={showLayers}>
        <FormGroup sx={{ paddingLeft: 12 }}>
          {izhsController.map((item) => {
            return (
              <FormControlLabel
                key={item.id}
                control={
                  <Stack flexDirection={'row'} alignItems={'center'}>
                    <Checkbox
                      checked={selectedLayers[item.localValue]}
                      onChange={(e) => {
                        setSelectedLayers({
                          ...selectedLayers,
                          [item.localValue]: e.target.checked ? true : false,
                        });
                      }}
                    />
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: 1,
                        marginLeft: 2,
                        background: item.background,
                      }}
                    />
                  </Stack>
                }
                size="small"
                label={item.label}
              />
            );
          })}
        </FormGroup>
      </Collapse>
    </>
  );
};

export default IzhsControl;
