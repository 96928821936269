import { TerritorySettings } from '../../../types/territories';
import { baseApi } from './baseApi';

export const layerSettingsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    setTerritorySettings: builder.mutation<void, TerritorySettings>({
      query: (body) => ({
        url: '/territories-settings',
        method: 'POST',
        body,
      }),
      invalidatesTags: ['LayerSettings'],
    }),
    getTerritorySettings: builder.query<
      TerritorySettings,
      { territoryId: string }
    >({
      query: ({ territoryId }) => `/territories-settings/${territoryId}/last`,
      providesTags: ['LayerSettings'],
    }),
    getAllTerritorySettingsVersions: builder.query<
      TerritorySettings[],
      { territoryId: string }
    >({
      query: ({ territoryId }) => `/territories-settings/${territoryId}/all`,
      providesTags: ['LayerSettings'],
    }),
  }),
});

export const {
  useSetTerritorySettingsMutation,
  useGetTerritorySettingsQuery,
  useGetAllTerritorySettingsVersionsQuery,
} = layerSettingsApi;
