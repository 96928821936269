import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { Box, Stack, Typography } from '@mui/material';

const LayersLegenda = () => {
  const activeLayerManager = useSelector(
    (state: RootState) => state.activeLayers,
  );

  return (
    <>
      {activeLayerManager.length > 0 && (
        <Stack
          sx={{
            zIndex: 1,
            backgroundColor: '#ffffff',
            width: 200,
            padding: 3,
            borderRadius: 3,
            overflow: 'auto',
            maxHeight: '50%',
            position: 'absolute',
            bottom: 24,
            left: 24,
          }}
        >
          <Typography
            fontSize={12}
            fontWeight={500}
            sx={{ color: '#8A8C8C', marginBottom: 1 }}
          >
            Слои
          </Typography>
          <Stack gap={4}>
            {activeLayerManager.map((item, index) => {
              return (
                <Stack gap={1} key={index}>
                  {item.name && (
                    <Typography fontSize={11}>{item.name}</Typography>
                  )}
                  {item.layer.map((item) => {
                    return (
                      <Stack
                        key={item.id}
                        gap={2}
                        flexDirection={'row'}
                        alignItems={'center'}
                      >
                        <Box
                          sx={{
                            width: 16,
                            height: 16,
                            borderRadius: 1,
                            backgroundColor: item.background,
                            border: `1px solid ${item.border}`,
                          }}
                        />
                        <Typography fontSize={11} sx={{ color: '#8A8C8C' }}>
                          {item.label}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default LayersLegenda;
