import { IconArrowDown, IconArrowRight } from '@brusnika.tech/ui-icons';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@brusnika.tech/ui-kit';
import { Box, Collapse, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { nspdController } from '../../common/layersControllers';
import { useAppDispatch } from '../../hooks';
// import { setActiveLayer } from '../store/slices/layersSettingsSlice';
import { setNspdLayer } from '../store/slices/tileLayerSlice';
import { setActiveLayer } from '../store/slices/activeLayersManager';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';

const NspdControl = () => {
  const dispatch = useAppDispatch();
  const [showLayers, setShowLayers] = useState(false);
  const [isAllLayersSelected, setIsAllLayersSelected] = useState(false);
  const [selectedLayers, setSelectedLayers] = useState<any>(() => {
    const storedLayers = localStorage.getItem('nspdLayers');
    return storedLayers
      ? JSON.parse(storedLayers)
      : {
          mkd: false,
          igd: false,
          other: false,
        };
  });

  useEffect(() => {
    setIsAllLayersSelected(
      Object.values(selectedLayers).some((value) => value),
    );
  }, [selectedLayers]);

  // const activeLayerManager = useSelector(
  //   (state: RootState) => state.activeLayers,
  // );

  useEffect(() => {
    localStorage.setItem('nspdLayers', JSON.stringify(selectedLayers));
    nspdController.map((item) => {
      dispatch(
        setActiveLayer({
          name: 'ЗУ (НСПД)',
          layer: [
            {
              label: item.label,
              isActive: selectedLayers[item.localValue],
              value: item.value,
              id: +item.id,
              background: item.background,
              border: item.borderColor,
              metaValue: item.metaValue,
            },
          ],
        }),
      );
      dispatch(
        setNspdLayer({
          value: item.value,
          isActive: selectedLayers[item.localValue],
        }),
      );
    });
  }, [selectedLayers]);

  return (
    <>
      <Stack flexDirection={'row'} alignItems={'center'}>
        <IconButton
          sx={{ width: 16, height: 16 }}
          onClick={() => {
            setShowLayers(!showLayers);
          }}
        >
          {showLayers ? (
            <IconArrowDown iconSize="medium" />
          ) : (
            <IconArrowRight iconSize="medium" />
          )}
        </IconButton>
        <FormControlLabel
          sx={{ paddingLeft: 2 }}
          control={
            <Checkbox
              checked={isAllLayersSelected}
              onChange={(e) => {
                setSelectedLayers({
                  mkd: e.target.checked,
                  igd: e.target.checked,
                  other: e.target.checked,
                });
              }}
            />
          }
          label={'ЗУ (НСПД)'}
        />
      </Stack>
      <Collapse in={showLayers}>
        <FormGroup sx={{ paddingLeft: 12 }}>
          {nspdController.map((item) => {
            return (
              <FormControlLabel
                key={item.id}
                control={
                  <Stack flexDirection={'row'} alignItems={'center'}>
                    <Checkbox
                      checked={selectedLayers[item.localValue]}
                      onChange={(e) => {
                        setSelectedLayers({
                          ...selectedLayers,
                          [item.localValue]: e.target.checked,
                        });
                      }}
                    />
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: 1,
                        marginLeft: 2,
                        background: item.background,
                        border: `1px solid ${item.borderColor}`,
                      }}
                    />
                  </Stack>
                }
                size="small"
                label={item.label}
              />
            );
          })}
        </FormGroup>
      </Collapse>
    </>
  );
};

export default NspdControl;
