import { SideSheet } from '@brusnika.tech/ui-portal';
import MapComponent from '../map/Map';
import { Dispatch, SetStateAction } from 'react';
import TradingMap from '../map/TradingMap';

type MonitoringRightSidebarProps = {
  setOverlapRightSidebar: Dispatch<SetStateAction<boolean>>;
  overlapRightSidebar: boolean;
  trades?: any;
};

const MonitoringRightSidebar = ({
  overlapRightSidebar,
  setOverlapRightSidebar,
  trades,
}: MonitoringRightSidebarProps) => {
  return (
    <SideSheet overlap={overlapRightSidebar}>
      {/* <MapComponent
        trades={trades}
        overlap={overlapRightSidebar}
        setOverlap={setOverlapRightSidebar}
      /> */}
      <TradingMap
        trades={trades}
        overlap={overlapRightSidebar}
        setOverlap={setOverlapRightSidebar}
      />
    </SideSheet>
  );
};

export default MonitoringRightSidebar;
