import { IconButton, TableCell } from '@brusnika.tech/ui-kit';
import { Status } from '@brusnika.tech/ui-kit';
import {
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import moment from 'moment';
import { useAppDispatch } from '../../hooks';
import { setKrt } from '../store/slices/krtSlice';
import { IconRegistry } from '@brusnika.tech/ui-icons';
import { setTradingKrtInfo } from '../store/slices/tradingKrtInfo';
import { useNavigate } from 'react-router-dom';
import { PATHS } from '../navigation/paths';

const MonitoringTable: React.FC<{ trades: any }> = ({ trades }) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const getStatus = (status: string) => {
    if (status === 'Не состоялся') {
      return (
        <Status size="medium" variant="error">
          Не состоялся
        </Status>
      );
    } else if (status === 'Отменен') {
      return (
        <Status size="medium" variant="error">
          Отменен
        </Status>
      );
    } else if (status === 'Состоялся') {
      return (
        <Status size="medium" variant="success">
          Состоялся
        </Status>
      );
    } else if (status === 'Единственный участник') {
      return (
        <Status size="medium" variant="warning">
          Единственный участник
        </Status>
      );
    }
  };

  const StyledTable = styled(Table)`
    display: grid;
    grid-template-columns: repeat(11, 1fr);
    border-bottom: 1px solid rgba(20, 25, 26, 0.08);
  `;
  const StyledTableHead = styled(TableHead)`
    display: contents;
  `;
  const StyledTableBody = styled(TableBody)`
    display: contents;
  `;
  const StyledTableRow = styled(TableRow)`
    display: contents;
  `;

  return (
    <StyledTable>
      <StyledTableHead>
        <StyledTableRow>
          <TableCell
            sx={{ padding: '16px 8px 0 24px' }}
            type="header"
            borderVariant="rightBottom"
          >
            Шифр КРТ
          </TableCell>
          <TableCell
            sx={{ padding: '16px 8px' }}
            type="header"
            borderVariant="rightBottom"
          >
            Статус Лота
          </TableCell>
          <TableCell
            sx={{ padding: '16px 8px' }}
            type="header"
            borderVariant="rightBottom"
          >
            Электронная площадка
          </TableCell>
          <TableCell
            sx={{ padding: '16px 8px' }}
            type="header"
            borderVariant="rightBottom"
          >
            Ссылка на извещение в ОЧ Реестра извещения
          </TableCell>
          <TableCell
            sx={{ padding: '16px 8px' }}
            type="header"
            borderVariant="rightBottom"
          >
            Дата и время начала подачи заявок
          </TableCell>
          <TableCell
            sx={{ padding: '16px 8px' }}
            type="header"
            borderVariant="rightBottom"
          >
            Дата и время начала аукциона
          </TableCell>
          <TableCell
            sx={{ padding: '16px 8px' }}
            type="header"
            borderVariant="rightBottom"
          >
            Наименование организации
          </TableCell>
          <TableCell
            sx={{ padding: '16px 8px' }}
            type="header"
            borderVariant="rightBottom"
          >
            Ссылка на КРТ
          </TableCell>
          <TableCell
            sx={{ padding: '16px 8px' }}
            type="header"
            borderVariant="rightBottom"
          >
            Описание лота
          </TableCell>
          <TableCell
            sx={{ padding: '16px  8px' }}
            type="header"
            borderVariant="rightBottom"
          >
            Начальная цена
          </TableCell>
          <TableCell
            sx={{ padding: '16px  8px' }}
            type="header"
            borderVariant="rightBottom"
          >
            Итоговая цена
          </TableCell>
        </StyledTableRow>
      </StyledTableHead>
      <StyledTableBody>
        {trades?.map((item: any) => (
          <StyledTableRow
            key={item.id}
            onClick={() => (item?.krt ? dispatch(setKrt(item?.krt)) : null)}
            style={{ cursor: 'pointer' }}
          >
            <TableCell
              sx={{ padding: '4px 8px 4px 24px' }}
              borderVariant="rightBottom"
            >
              <Stack flexDirection={'row'} alignItems={'center'}>
                {item?.krt?.code}
                <IconButton
                  onClick={() => {
                    dispatch(setTradingKrtInfo(item));
                    navigate(
                      PATHS.TRADING_MONITORING_CARD.replace(':card', item.id),
                    );
                  }}
                >
                  <IconRegistry iconSize="medium" />
                </IconButton>
              </Stack>
            </TableCell>
            <TableCell sx={{ padding: '4px 8px' }} borderVariant="rightBottom">
              {getStatus(item?.lotStatus)}
            </TableCell>
            <TableCell sx={{ padding: '4px 8px' }} borderVariant="rightBottom">
              {item?.platform}
            </TableCell>
            <TableCell sx={{ padding: '4px 8px' }} borderVariant="rightBottom">
              <a
                target="_blank"
                href={`${item.noticesUrl ? item.noticesUrl : ''}`}
              >
                {item.noticesUrl?.slice(0, 20)}...
              </a>
            </TableCell>
            <TableCell sx={{ padding: '4px 8px' }} borderVariant="rightBottom">
              {moment(item?.startRequestsDate).format('DD.MM.yyyy HH:mm')}
            </TableCell>
            <TableCell sx={{ padding: '4px 8px' }} borderVariant="rightBottom">
              {moment(item?.startAuctionDate).format('DD.MM.yyyy HH:mm')}
            </TableCell>
            <TableCell sx={{ padding: '4px 8px' }} borderVariant="rightBottom">
              {item?.organizationName}
            </TableCell>
            <TableCell sx={{ padding: '4px 8px' }} borderVariant="rightBottom">
              <a target="_blank" href={`${item.krtUrl ? item.krtUrl : ''}`}>
                {item?.krtUrl?.slice(0, 20)}
              </a>
            </TableCell>
            <TableCell sx={{ padding: '4px 8px' }} borderVariant="rightBottom">
              {item?.lotDescription}
            </TableCell>
            <TableCell sx={{ padding: '4px 8px' }} borderVariant="rightBottom">
              {item?.startPrice}
            </TableCell>
            <TableCell sx={{ padding: '4px 8px' }} borderVariant="rightBottom">
              {item?.finalPrice}
            </TableCell>
          </StyledTableRow>
        ))}

        {!trades?.length && (
          <TableCell sx={{ padding: '4px 8px' }} borderVariant="bottom">
            Данных нет
          </TableCell>
        )}
      </StyledTableBody>
    </StyledTable>
  );
};

export default MonitoringTable;
