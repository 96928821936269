import { MouseEvent } from 'react';
import { Button, IconButton } from '@brusnika.tech/ui-kit';
import { UserProfileBar } from '@brusnika.tech/ui-portal';
import { Divider, Menu, Stack, Typography } from '@mui/material';
import { useState } from 'react';
import { IconExit, IconQuestionOutline } from '@brusnika.tech/ui-icons';
import { auth } from '../store/auth/api';
import { useGetCurrentUserQuery } from '../store/services/auth';

const UserMenu = () => {
  const { data } = useGetCurrentUserQuery();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const openHelpPage = () => {
    window.open('https://help.brusnika.ru', '_blank');
  };

  return (
    <>
      <IconButton onClick={handleClick} variant="standard">
        <UserProfileBar
          topText={data?.fullName}
          bottomText={data?.email}
          avatarAlt={data?.fullName}
          isCollapsed
        />
      </IconButton>
      <Menu anchorEl={anchorEl} onClose={handleClose} open={open}>
        <Stack width="240px" px={2}>
          <Stack py={2}>
            <UserProfileBar
              topText={data?.fullName}
              bottomText={data?.email}
              avatarAlt={data?.fullName}
            />
          </Stack>
          <Stack>
            <Divider />
            <Button
              sx={{ justifyContent: 'flex-start', gap: 2 }}
              variant="clean"
              onClick={openHelpPage}
            >
              <IconQuestionOutline iconSize="medium" />
              <span>Брусника.Помощь</span>
            </Button>
            <Divider />
            <Button
              sx={{ justifyContent: 'flex-start', gap: 2 }}
              variant="clean"
              onClick={() => {
                auth.signOut();
              }}
              // className={styles.logout}
            >
              <IconExit iconSize="medium" />
              <span>Выйти из сервиса</span>
            </Button>
          </Stack>
        </Stack>
      </Menu>
    </>
  );
};

export default UserMenu;
