import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type LayerMetaInfo = { metaValue: string; layerMeta: any[] };

const initialState: LayerMetaInfo = { metaValue: '', layerMeta: [] };

const layerMetaInfoSlice = createSlice({
  name: 'layerMetaInfoSlice',
  initialState: initialState,
  reducers: {
    setLayerMetaInfo: (state, action: PayloadAction<LayerMetaInfo>) => {
      state.metaValue = action.payload.metaValue;
      state.layerMeta = action.payload.layerMeta;
    },
    resetLayerMetaInfo: (state) => {
      return initialState;
    },
  },
});

export const { setLayerMetaInfo, resetLayerMetaInfo } =
  layerMetaInfoSlice.actions;
export const { reducer: layerMetaInfoReducer } = layerMetaInfoSlice;
