import { Content, Drawer, Module, useLayout } from '@brusnika.tech/ui-portal';
import { useEffect, useState } from 'react';
import MapComponent from '../../map/Map';
import MainHeader from '../../headers/MainHeader';
import Sidebar from '../../sidebar/Sidebar';
import KrtRightSidebar from '../../rightSideBarContent/KrtRightSidebar';
import { Box, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { Popup } from 'react-map-gl';
// import { legendaColors } from '../../../common/constants';
import { Hint } from '@brusnika.tech/ui-kit';
import { Building } from '../../../types/buildings';
import {
  setBuildingLayer,
  updateBuildingLayer,
} from '../../store/slices/buildingLayerSlice';
import { useAppDispatch } from '../../../hooks';
import KrtMap from '../../map/KrtMap';
import LayerMetaPopup from '../../layerMetaPopup/LayerMetaPopup';
import LayersLegenda from '../../layersLegenda/LayersLegenda';
import { useParams } from 'react-router-dom';
import Seo from '../../seo/Seo';
import {
  useGetTerritoryKrtQuery,
  useGetTerritoryQuery,
} from '../../store/services/territories';

const KrtPage = () => {
  const {
    toggleRightDrawer,
    setRightDrawerWidth,
    openedRightDrawer,
    setOpenedRightDrawer,
  } = useLayout();
  // const buildingLayer = useSelector((state: RootState) => state.buildingLayer);
  // const [popup, setPopup] = useState(true);
  // const dispatch = useAppDispatch();
  // const [newColor, setNewColor] = useState<Building[]>([]);
  const { id }: { id?: string } = useParams();
  const { krtId }: { krtId?: string } = useParams();
  const [headTitle, setHeadTitle] = useState('');

  const { data: territory, isSuccess } = useGetTerritoryQuery(
    { territoryId: id! },
    { skip: !id },
  );

  const { data: krt, isSuccess: successKrt } = useGetTerritoryKrtQuery(
    { krtId: krtId! },
    { skip: !krtId },
  );

  useEffect(() => {
    if (!id) {
      setHeadTitle('КРТ');
    } else if (territory && !krtId && isSuccess && !successKrt) {
      setHeadTitle(territory.properties.name);
    } else if (krt && successKrt && isSuccess) {
      setHeadTitle(`${territory?.properties.name} - ${krt.code}`);
    }
  }, [id, territory, krtId, krt]);

  // const updatedColors = buildingLayer.map((building) => {
  //   const matchingBuilding = newColor.find(
  //     (item) => item.value === building.value,
  //   );
  //   if (matchingBuilding) {
  //     return { ...building, color: matchingBuilding.color };
  //   }
  //   return building;
  // });

  // useEffect(() => {
  //   dispatch(updateBuildingLayer(updatedColors));
  // }, [newColor]);

  useEffect(() => {
    // setRightDrawerWidth(350);
    setOpenedRightDrawer(localStorage.getItem('layerController') === 'true');
  }, []);

  // useEffect(() => {
  //   console.log('building layer', buildingLayer);
  //   console.log('newColor layer', newColor);
  // }, [buildingLayer, newColor]);

  return (
    <Module
      leftSidebar={
        <Drawer resizable>
          <Sidebar />
        </Drawer>
      }
    >
      <>
        <Seo title={headTitle} />
        <Content
          header={<MainHeader />}
          sx={{ overflow: 'hidden' }}
          // header={<MainHeader />}
          rightSidebar={
            <>
              {openedRightDrawer && (
                <Stack sx={{ position: 'relative', width: 350 }}>
                  <KrtRightSidebar />
                </Stack>
              )}
            </>
          }
        >
          <Stack sx={{ height: '100%', position: 'relative' }}>
            {id && <LayersLegenda />}
            <LayerMetaPopup />
            {/* {buildingLayer.length > 0 && (
            <Stack
              gap={2}
              sx={{
                zIndex: 1,
                margin: 'auto 0 24px 24px',
                backgroundColor: '#ffffff',
                width: 200,
                padding: 3,
                borderRadius: 3,
                overflow: 'auto',
                maxHeight: '50%',
              }}
            >
              <Typography
                fontSize={12}
                fontWeight={500}
                sx={{ color: '#8A8C8C' }}
              >
                Типы зданий
              </Typography>
              {buildingLayer.map((item) => {
                return (
                  <Stack
                    key={item.id}
                    gap={2}
                    flexDirection={'row'}
                    alignItems={'center'}
                  >
                    <Hint
                      visibilityTrigger="click"
                      header="Выберите цвет"
                      content={
                        <Stack flexDirection={'row'} gap={2}>
                          {legendaColors.map((color) => {
                            return (
                              <Box
                                key={color.id}
                                onClick={() => {
                                  const existingColor = newColor.find(
                                    (element) => element.value === item.value,
                                  );
                                  if (existingColor) {
                                    const updatedNewColor = newColor.map(
                                      (element) =>
                                        element.value === item.value
                                          ? {
                                              color: color.color,
                                              id: item.id,
                                              label: item.label,
                                              value: item.value,
                                            }
                                          : element,
                                    );
                                    setNewColor(updatedNewColor);
                                  } else {
                                    setNewColor([
                                      ...newColor,
                                      {
                                        color: color.color,
                                        id: item.id,
                                        label: item.label,
                                        value: item.value,
                                      },
                                    ]);
                                  }
                                }}
                                sx={{
                                  width: 16,
                                  height: 16,
                                  borderRadius: 1,
                                  cursor: 'pointer',
                                  backgroundColor: `rgba(${color.color})`,
                                }}
                              />
                            );
                          })}
                        </Stack>
                      }
                    >
                      <Box
                        sx={{
                          width: 16,
                          height: 16,
                          borderRadius: 1,
                          cursor: 'pointer',
                          backgroundColor: `rgba(${item.color})`,
                        }}
                      />
                    </Hint>
                    <Typography fontSize={11} sx={{ color: '#8A8C8C' }}>
                      {item.label}
                    </Typography>
                  </Stack>
                );
              })}
            </Stack>
          )} */}
            {/* <MapComponent /> */}
            <KrtMap />
          </Stack>
        </Content>
      </>
    </Module>
  );
};

export default KrtPage;
