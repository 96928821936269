import { PropsWithChildren } from 'react';
import { Stack } from '@mui/material';
import { Spinner } from '@brusnika.tech/ui-kit';
import { useGetCurrentUserQuery } from '../store/services/auth';

const AuthLayout = ({ children }: PropsWithChildren) => {
  const { data } = useGetCurrentUserQuery();

  if (!data) {
    return (
      <Stack
        alignItems="center"
        height="100vh"
        justifyContent="center"
        width="100vw"
      >
        <Spinner size="large" text="Загрузка" />
      </Stack>
    );
  }

  return <>{children}</>;
};

export default AuthLayout;
