import { baseApi } from './baseApi';

export const layerStylesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getAllLayerStyles: builder.query<any, void>({
      query: () => `/styles/config`,
      providesTags: ['LayerStyles'],
    }),
    getLayerMeta: builder.query<
      any,
      { latitude: number; longitude: number; layerName: string }
    >({
      query: ({ latitude, longitude, layerName }) =>
        `/territories/meta/all?latitude=${latitude}&longitude=${longitude}${layerName}`,
      providesTags: ['LayerStyles'],
    }),
  }),
});

export const { useGetAllLayerStylesQuery, useGetLayerMetaQuery } =
  layerStylesApi;
