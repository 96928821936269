import { auth } from "../auth/api";
import { baseApi } from "./baseApi";

export interface UserInfo {
  userId: string;
  email: string;
  fullName: string;
}

export const authApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCurrentUser: builder.query<UserInfo | undefined, void>({
      queryFn: async (_, _api, _extraOptions, _baseQuery) => {
        try {
          const user: UserInfo = await auth.fetchUser();
          return { data: user };
        } catch (e) {
          auth.login();
          return { data: undefined };
        }
      },
    }),
  }),
});

export const { useGetCurrentUserQuery } = authApi;
