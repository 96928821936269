import { Routes, Route, Navigate } from 'react-router-dom';
import { PATHS } from './paths';
import KrtPage from '../pages/krt/Krt';
import TradingMonitoringPage from '../pages/tradingMonitoring/TradingMonitoring';

const Router = () => {
  return (
    <Routes>
      <Route path="*" element={<Navigate to={PATHS.KRT} />} />
      <Route path={PATHS.KRT} element={<KrtPage />} />
      <Route path={PATHS.KRT_TERRITORY} element={<KrtPage />} />
      <Route path={PATHS.TERRITORY_KRT} element={<KrtPage />} />

      <Route
        path={PATHS.TRADING_MONITORING}
        element={<TradingMonitoringPage />}
      />
      <Route
        path={PATHS.TRADING_MONITORING_TERRITORY}
        element={<TradingMonitoringPage />}
      />
      <Route
        path={PATHS.TRADING_MONITORING_TERRITORY_KRT}
        element={<TradingMonitoringPage />}
      />
      <Route
        path={PATHS.TRADING_MONITORING_CARD}
        element={<TradingMonitoringPage />}
      />
    </Routes>
  );
};

export default Router;
