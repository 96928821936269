import DeckGL from '@deck.gl/react';
import { Map } from 'react-map-gl';
import {
  accessToken,
  // getFillBuildingsColor,
  // mapStyle,
} from '../../common/constants';
import { GeoJsonLayer, PolygonLayer, TextLayer } from '@deck.gl/layers';
import {
  useGetAllKrtsQuery,
  useGetTerritoriesQuery,
  useGetTerritoryKrtQuery,
  useGetTerritoryQuery,
} from '../store/services/territories';
import { IconButton } from '@brusnika.tech/ui-kit';
import {
  IconAdd,
  IconArrowLeft,
  IconArrowRight,
  IconCollapse,
  IconExpand,
  IconLayers,
  IconRemove,
} from '@brusnika.tech/ui-icons';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FlyToInterpolator } from '@deck.gl/core';
import { useAppDispatch } from '../../hooks';
import { setKrt } from '../store/slices/krtSlice';
import { RootState } from '../store/store';
import { useSelector } from 'react-redux';
import { setTradingKrtInfo } from '../store/slices/tradingKrtInfo';
import { MVTLayer } from '@deck.gl/geo-layers';
import { Feature, Geometry } from 'geojson';
import { NspdLayerPropertiesType } from '../../types/buildings';
import { useLayout } from '@brusnika.tech/ui-portal';

type MapComponentProps = {
  setOverlap: Dispatch<SetStateAction<boolean>>;
  overlap: boolean;
  trades?: any;
};

type Trade = {
  krtId: number;
  startAuctionDate: string; // Assuming date is a string in ISO format
  [key: string]: any; // For other fields
};

const TradingMap = ({ setOverlap, overlap, trades }: MapComponentProps) => {
  const dispatch = useAppDispatch();
  const { toggleLeftDrawer, openedLeftDrawer, toggleRightDrawer } = useLayout();
  const { id }: { id?: string } = useParams();
  const { card }: { card?: string } = useParams();
  const { krtId }: { krtId?: string } = useParams();
  const { data: territory } = useGetTerritoryQuery(
    {
      territoryId: id!,
    },
    { skip: !id },
  );

  const mapSettings = useSelector((state: RootState) => state.mapSetting);
  const krtRedux = useSelector((state: RootState) => state.krt);
  const tradeInfo = useSelector((state: RootState) => state.tradingInfo);
  const buildingLayer = useSelector((state: RootState) => state.buildingLayer);
  const [buildingLayerUrl, setBuildingLayerUrl] = useState('');

  useEffect(() => {
    if (buildingLayer.length > 0) {
      setBuildingLayerUrl(
        `https://martin.brusnika.devcontour.ru/function_zxy_query_building/{z}/{x}/{y}?building=[${buildingLayer
          .map((item) => `"${item.value}"`)
          .join(',')}]`,
      );
    } else {
      setBuildingLayerUrl('');
    }
  }, [buildingLayer, buildingLayerUrl]);

  const { data: cardKrt } = useGetTerritoryKrtQuery(
    {
      krtId: tradeInfo?.krtId,
    },
    { skip: !tradeInfo?.krtId },
  );

  const { data: krtReduxData } = useGetTerritoryKrtQuery(
    {
      krtId: krtRedux?.id,
    },
    { skip: !krtRedux?.id },
  );
  const { data: krt } = useGetTerritoryKrtQuery(
    {
      krtId: krtId!,
    },
    { skip: !krtId },
  );
  const { data: territories } = useGetTerritoriesQuery();
  const { data: AllKrts } = useGetAllKrtsQuery();

  useEffect(() => {
    if (krt) {
      dispatch(setKrt(krt));
    }
  }, [krt]);

  const [zoom, setZoom] = useState(11);
  const [mapWidth, setMapWidth] = useState(0);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'Не состоялся':
      case 'Отменен':
        return [250, 50, 5];
      case 'Состоялся':
        return [38, 250, 5];
      case 'Единственный участник':
        return [246, 250, 5];
      default:
        return [255, 255, 255];
    }
  };

  const getPolygonCoordinates = (d: any) => {
    if (d?.geometry) {
      return d?.geometry?.coordinates[0];
    } else if (d?.krt) {
      return d?.krt?.geometry?.coordinates[0];
    } else return [];
  };

  const getUniqueTrades = (trades: Trade[]): Trade[] => {
    const sortedTrades = trades?.slice().sort((a, b) => {
      const dateA = new Date(a.startAuctionDate).getTime();
      const dateB = new Date(b.startAuctionDate).getTime();
      return dateB - dateA;
    });

    const uniqueTradesObj: { [krtId: number]: Trade } = {};

    sortedTrades?.forEach((trade) => {
      if (!uniqueTradesObj[trade.krtId]) {
        uniqueTradesObj[trade.krtId] = trade;
      }
    });

    return Object.values(uniqueTradesObj);
  };

  const filledPolygonLayer = new PolygonLayer<any>({
    id: 'filledPolygonLayer',
    data: getUniqueTrades(trades),
    getPolygon: (d: any) => getPolygonCoordinates(d),
    getFillColor: (d: any) => {
      return getStatusColor(d.lotStatus) as any;
    },
    opacity: 0.1,
    getLineColor: [255, 255, 255],
    getLineWidth: 20,
    lineWidthMinPixels: 1,
    pickable: true,
    onClick: (item) => {
      dispatch(setTradingKrtInfo(item.object));
    },
  });

  const territoryLayer = new GeoJsonLayer({
    id: 'territoryLayer',
    data: territory && (territory.geometry as any),
    lineWidthMinPixels: 2,
    lineWidthScale: 1,
    filled: false,
    getLineColor: [187, 0, 0],
  });

  const polygonsLayer = new GeoJsonLayer({
    id: 'polygonsLayer',
    data: AllKrts && AllKrts,
    lineWidthMinPixels: 2,
    lineWidthScale: 1,
    filled: false,
  });

  const polygonsLayerText = new TextLayer({
    id: 'polygonsLayerText',
    data: AllKrts && AllKrts,
    getPosition: (item) => item.centroid.coordinates,
    getText: (item) => item.code,
    getSize: 13,
    sizeMaxPixels: 13,
    getTextAnchor: 'middle',
    sizeUnits: 'meters',
    getAlignmentBaseline: 'center',
    fontFamily: 'Arial',
    characterSet: 'auto',
  });

  const territoriesLayer = new GeoJsonLayer({
    id: 'territoriesLayer',
    data: territories?.features as any,
    lineWidthMinPixels: 2,
    lineWidthScale: 1,
    filled: false,
    getLineColor: [187, 0, 0],
  });

  // const buildingsLayer = buildingLayerUrl
  //   ? new MVTLayer<NspdLayerPropertiesType>({
  //       id: 'buildingsLayer',
  //       data: [buildingLayerUrl],
  //       onClick(pickingInfo, event) {
  //         console.log('pickingInfo.object', pickingInfo.object);
  //       },
  //       getFillColor: (f: Feature<Geometry, NspdLayerPropertiesType>): any => {
  //         const color = buildingLayer.find(
  //           (item) => item.value === f.properties.building,
  //         );
  //         return color?.color ? color.color : [0, 0, 0, 0];
  //       },
  //       updateTriggers: {
  //         getFillColor: buildingLayer,
  //       },
  //       getLineColor: [192, 192, 192],
  //       getPointRadius: 2,
  //       pointRadiusUnits: 'pixels',
  //       stroked: false,
  //       pickable: true,
  //     })
  //   : null;

  const [viewport, setViewport] = useState<any>({
    longitude: 37.603074966,
    latitude: 55.742085689,
    zoom: 11,
    width: 0,
  });

  const handleFlyTo = (longitude: any, latitude: any, zoom: number) => {
    setViewport({
      ...viewport,
      longitude,
      latitude,
      zoom: zoom,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  useEffect(() => {
    if (territory && !krtId && !card) {
      handleFlyTo(
        territory?.properties.center.coordinates[0],
        territory?.properties.center.coordinates[1],
        11,
      );
    }
  }, [territory, krtId, krtRedux, card]);

  // useEffect(() => {
  //   if (krt) {
  //     handleFlyTo(
  //       krt?.centroid?.coordinates[0],
  //       krt?.centroid?.coordinates[1],
  //       16,
  //     );
  //   }
  // }, [krt]);

  // useEffect(() => {
  //   if (cardKrt) {
  //     handleFlyTo(
  //       cardKrt?.centroid?.coordinates[0],
  //       cardKrt?.centroid?.coordinates[1],
  //       16,
  //     );
  //   }
  // }, [cardKrt]);

  useEffect(() => {
    if (krtReduxData && !Array.isArray(krtReduxData)) {
      handleFlyTo(
        krtReduxData?.centroid?.coordinates[0],
        krtReduxData?.centroid?.coordinates[1],
        16,
      );
    }
  }, [krtReduxData]);

  useEffect(() => {
    setViewport({
      ...viewport,
      zoom: zoom,
      transitionDuration: 300,
      transitionInterpolator: new FlyToInterpolator(),
    });
  }, [zoom]);

  useEffect(() => {
    setViewport({
      ...viewport,
      width: mapWidth,
      transitionInterpolator: '',
    });
  }, [mapWidth]);

  return (
    <>
      <DeckGL
        initialViewState={viewport}
        controller
        onResize={(d) => {
          setMapWidth(d.width);
        }}
        layers={[
          territoriesLayer,
          filledPolygonLayer,
          polygonsLayer,
          polygonsLayerText,
        ]}
      >
        <IconButton
          size="large"
          variant="outline"
          onClick={() => {
            overlap ? setOverlap(false) : setOverlap(true);
          }}
          sx={{
            position: 'absolute',
            top: 24,
            left: 24,
          }}
        >
          {overlap ? <IconArrowLeft /> : <IconArrowRight />}
        </IconButton>

        <Stack sx={{ position: 'absolute', bottom: 48, right: 24, gap: 2 }}>
          <IconButton
            onClick={() => {
              if (zoom < 20) {
                setZoom(zoom + 1);
              }
            }}
            size="large"
            variant="outline"
          >
            <IconAdd iconSize="medium" />
          </IconButton>
          <IconButton
            onClick={() => {
              if (zoom > 1) {
                setZoom(zoom - 1);
              }
            }}
            size="large"
            variant="outline"
          >
            <IconRemove iconSize="medium" />
          </IconButton>
        </Stack>
        {mapSettings.mapStyle && (
          <Map
            mapStyle={mapSettings.mapStyle}
            mapboxAccessToken={accessToken}
          />
        )}
      </DeckGL>
    </>
  );
};

export default TradingMap;
