import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const baseApi = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://service.brusnika.devcontour.ru',
    credentials: 'include',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }),
  tagTypes: [
    'Territories',
    'Trades',
    'Buildings',
    'LayerStyles',
    'LayerSettings',
  ],
  endpoints: () => ({}),
});
