import { IconAdd, IconTrash } from '@brusnika.tech/ui-icons';
import {
  Button,
  Checkbox,
  Dialog,
  DialogFooter,
  FormControlLabel,
  Hint,
  IconButton,
  TableCell,
} from '@brusnika.tech/ui-kit';
import {
  Box,
  Stack,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TextField,
  Typography,
  styled,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { stylesForIzhs } from '../../common/constants';
import { useAppDispatch } from '../../hooks';
import { setActiveLayer } from '../store/slices/activeLayersManager';
import { setIzhsLayer } from '../store/slices/tileLayerSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { izhsController } from '../../common/layersControllers';

type CreateCustomIzhsLayerProps = {
  isOpenedDialog: boolean;
  setIsOpenedDialog: () => void;
};

const CreateCustomIzhsLayer = ({
  isOpenedDialog,
  setIsOpenedDialog,
}: CreateCustomIzhsLayerProps) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<any[]>([]);
  const [selectedColor, setSelectedColor] = useState('');
  const [lastId, setLastId] = useState(izhsController.length + data.length + 1);

  useEffect(() => {
    console.log('12345', lastId);
  }, [lastId]);

  const handleClick = () => {
    const newData = [
      ...data,
      {
        id: lastId + 1,
        label: '',
        background: '#026000',
        startRangeValue: null,
        endRangeValue: null,
        checked: false,
      },
    ];
    setLastId(lastId + 1);
    setData(newData);
  };

  useEffect(() => {
    const newData = [
      ...data,
      {
        id: lastId,
        label: '',
        background: '#026000',
        startRangeValue: null,
        endRangeValue: null,
        checked: false,
      },
    ];
    setData(newData);
  }, []);

  const resetValues = () => {
    const initialData = [
      {
        id: lastId + 1,
        label: '',
        background: '#026000',
        startRangeValue: null,
        endRangeValue: null,
        checked: false,
      },
    ];
    setData(initialData);
    data.map((item) => {
      dispatch(
        setActiveLayer({
          name: 'ОКС ИЖД (ГИС ЖКХ)',
          layer: [
            {
              label: item.label,
              isActive: false,
              value: `${item.startRangeValue},${item.endRangeValue}`,
              id: item.id,
              background: item.background,
              // metaValue: item.metaValue,
              customLayer: true,
            },
          ],
        }),
      );
      dispatch(
        setIzhsLayer({
          value: `${item.startRangeValue},${item.endRangeValue}`,
          isActive: false,
        }),
      );
    });
    // const newData = data.map((item) => ({
    //   id: item.id,
    //   label: '',
    //   startRangeValue: null,
    //   endRangeValue: null,
    //   checked: false,
    //   background: '#026000',
    // }));
    // setData(newData);
  };

  const handleDelete = (index: number) => {
    const newData = [...data];
    newData.splice(index, 1);
    setData(newData);
  };

  const addlayer = () => {
    data.map((item) => {
      dispatch(
        setActiveLayer({
          name: 'ОКС ИЖД (ГИС ЖКХ)',
          layer: [
            {
              label: item.label,
              isActive: item.checked,
              value: `${item.startRangeValue},${item.endRangeValue}`,
              id: item.id,
              background: item.background,
              // metaValue: item.metaValue,
              customLayer: true,
            },
          ],
        }),
      );
      dispatch(
        setIzhsLayer({
          value: `${item.startRangeValue},${item.endRangeValue}`,
          isActive: item.checked,
        }),
      );
    });
  };

  const activeLayerManager = useSelector(
    (state: RootState) => state.activeLayers,
  );

  useEffect(() => {
    console.log('activeLayerManager', activeLayerManager);
  }, [activeLayerManager]);

  useEffect(() => {
    console.log('data', data);
  }, [data]);

  return (
    <Dialog
      PaperProps={{ sx: { width: '480px' } }}
      title="Свойство слоя ИЖС"
      onClose={setIsOpenedDialog}
      open={isOpenedDialog}
      footer={
        <DialogFooter
          confirmButtonHandler={() => {
            addlayer();
            setIsOpenedDialog();
          }}
          optionalButtonHandler={resetValues}
          onClose={setIsOpenedDialog}
        />
      }
    >
      <Stack width={'100%'}>
        <Stack
          paddingBottom={3}
          borderBottom={'1px solid rgba(20, 25, 26, 0.12)'}
          flexDirection={'row'}
        >
          <Typography
            sx={{ marginRight: '57px' }}
            fontWeight={500}
            fontSize={11}
          >
            Стиль
          </Typography>
          <Typography
            sx={{ marginRight: '100px' }}
            fontWeight={500}
            fontSize={11}
          >
            Значения
          </Typography>
          <Typography fontWeight={500} fontSize={11}>
            Границы
          </Typography>
        </Stack>
        {data.map((item, index) => (
          <Stack
            paddingBottom={3}
            paddingTop={3}
            borderBottom={'1px solid rgba(20, 25, 26, 0.12)'}
          >
            <Stack flexDirection={'row'} alignItems={'center'}>
              <Stack
                marginRight={'47px'}
                flexDirection={'row'}
                alignItems={'center'}
              >
                <Checkbox
                  checked={item.checked}
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].checked = e.target.checked;
                    setData(newData);
                  }}
                />
                <Hint
                  sx={{ maxWidth: 240 }}
                  header="Выбор стиля"
                  placement="top-start"
                  PopperProps={{ sx: { position: 'absolute' } }}
                  content={
                    <Stack
                      paddingTop={2}
                      paddingBottom={2}
                      alignItems={'center'}
                      flexDirection={'row'}
                    >
                      <Typography fontSize={11}>Цвет</Typography>
                      <Stack
                        marginLeft={2}
                        flexDirection={'row'}
                        gap={2}
                        flexWrap={'wrap'}
                      >
                        {stylesForIzhs.map((color) => {
                          return (
                            <Box
                              onClick={() => {
                                // console.log(color.color);
                                setSelectedColor(color.color);
                                const newData = [...data];
                                newData[index].background = color.color;
                                setData(newData);
                              }}
                              sx={{
                                width: 16,
                                height: 16,
                                borderRadius: 1,
                                cursor: 'pointer',
                                backgroundColor: color.color,
                                border:
                                  color.color === selectedColor
                                    ? '1px solid #14191A'
                                    : '',
                              }}
                            />
                          );
                        })}
                      </Stack>
                    </Stack>
                  }
                >
                  <Box
                    sx={{
                      width: 16,
                      height: 16,
                      borderRadius: 1,
                      marginLeft: 2,
                      background: data[index].background,
                      cursor: 'pointer',
                    }}
                  />
                </Hint>
              </Stack>
              <TextField
                sx={{ width: '110px', marginRight: '39px' }}
                fullWidth
                value={item.label}
                onChange={(e) => {
                  const newData = [...data];
                  newData[index].label = e.target.value;
                  setData(newData);
                }}
              />
              <Stack
                marginRight={'26px'}
                flexDirection={'row'}
                alignItems={'center'}
              >
                <TextField
                  sx={{ width: '67px' }}
                  fullWidth
                  type="number"
                  value={item.startRangeValue}
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].startRangeValue = parseInt(e.target.value);
                    setData(newData);
                  }}
                />
                <Typography
                  marginLeft={'10px'}
                  marginRight={'10px'}
                  fontSize={11}
                >
                  -
                </Typography>
                <TextField
                  sx={{ width: '67px' }}
                  fullWidth
                  type="number"
                  value={item.endRangeValue}
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].endRangeValue = parseInt(e.target.value);
                    setData(newData);
                  }}
                />
              </Stack>
              <IconButton
                onClick={() => {
                  handleDelete(index);
                  dispatch(
                    setActiveLayer({
                      name: 'ОКС ИЖД (ГИС ЖКХ)',
                      layer: [
                        {
                          label: item.label,
                          isActive: false,
                          value: `${item.startRangeValue},${item.endRangeValue}`,
                          id: item.id,
                          background: item.background,
                          // metaValue: item.metaValue,
                        },
                      ],
                    }),
                  );
                  dispatch(
                    setIzhsLayer({
                      value: `${item.startRangeValue},${item.endRangeValue}`,
                      isActive: false,
                    }),
                  );
                }}
                size="small"
              >
                <IconTrash iconSize="small" sx={{ color: '#8A8C8C' }} />
              </IconButton>
            </Stack>
          </Stack>
        ))}
        <Stack marginTop={7} flexDirection={'row'} alignItems={'center'}>
          <Typography marginRight={1} children={'Добавить класс'} />
          <IconButton onClick={handleClick} size="small">
            <IconAdd iconSize="small" />
          </IconButton>
        </Stack>
      </Stack>
    </Dialog>
  );
};

export default CreateCustomIzhsLayer;
