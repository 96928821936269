import { Checkbox, FormControlLabel } from '@brusnika.tech/ui-kit';
import { Box, Stack } from '@mui/material';
import { useAppDispatch } from '../../hooks';
// import { setActiveLayer } from '../store/slices/layersSettingsSlice';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { setActiveLayer } from '../store/slices/activeLayersManager';

const GranicaNp = () => {
  const dispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState(
    localStorage.getItem('granicaNP') === 'true',
  );
  const activeLayerManager = useSelector(
    (state: RootState) => state.activeLayers,
  );

  useEffect(() => {
    localStorage.setItem('granicaNP', `${isChecked}`);
    dispatch(
      setActiveLayer({
        layer: [
          {
            label: 'Граница НП (OSM)',
            isActive: isChecked,
            value: 'Граница_НП',
            id: 1,
            background: '',
            border: '#BB0000',
            metaValue: 'Граница_НП',
          },
        ],
      }),
    );
  }, [isChecked]);

  return (
    <>
      <Stack flexDirection={'row'} alignItems={'center'}>
        <FormControlLabel
          control={
            <Stack flexDirection={'row'} alignItems={'center'}>
              <Checkbox
                checked={isChecked}
                onChange={(e) => {
                  setIsChecked(e.target.checked);
                }}
              />
              <Box
                sx={{
                  width: 16,
                  height: 16,
                  borderRadius: 1,
                  marginLeft: 2,
                  border: '1px solid #BB0000',
                }}
              />
            </Stack>
          }
          label={'Граница НП (OSM)'}
        />
      </Stack>
    </>
  );
};

export default GranicaNp;
