import { Color } from '@deck.gl/core';
import { Feature, Geometry } from 'geojson';
// import { BuildingPropertiesType } from '../types/buildings';

export const accessToken =
  'pk.eyJ1IjoiYW5kcmVldjEwNTciLCJhIjoiY2x2MmRicDlrMGU0dTJycDhtNHp0d3p4NyJ9.e2oV6W3on9HIJd_Bg5TjWA';
export const mapStyle =
  'mapbox://styles/andreev1057/clw4wqp7902op01qu3q15fta5/draft';

export const tradingMultiboxOptions = [
  {
    label: 'Состоялся',
    value: 'completed',
    id: 1,
  },
  {
    label: 'Не состоялся',
    value: 'failed',
    id: 2,
  },
  {
    label: 'Отменен',
    value: 'canceled',
    id: 3,
  },
  {
    label: 'Единственный участник',
    value: 'solo',
    id: 4,
  },
];

// export const getFillBuildingsColor = (
//   f: Feature<Geometry, BuildingPropertiesType>,
// ): Color => {
//   switch (f.properties.building) {
//     case 'civic':
//       return [158, 45, 0];
//     case 'church':
//       return [45, 158, 0];
//     case 'hangar':
//       return [0, 45, 158];
//     case 'tower':
//       return [158, 0, 45];
//     case 'dormitory':
//       return [0, 158, 45];
//     case 'storage_tank':
//       return [45, 0, 158];
//     case 'garages':
//       return [158, 90, 0];
//     case 'shed':
//       return [90, 158, 0];
//     case 'industrial':
//       return [0, 90, 158];
//     case 'apartments':
//       return [158, 0, 90];
//     case 'construction':
//       return [0, 158, 90];
//     case 'presbytery':
//       return [90, 0, 158];
//     case 'arch':
//       return [158, 135, 0];
//     case 'residential':
//       return [135, 158, 0];
//     case 'stage':
//       return [0, 135, 158];
//     case 'yes':
//       return [158, 0, 135];
//     case 'stadium':
//       return [0, 158, 135];
//     case 'detached':
//       return [135, 0, 158];
//     case 'service':
//       return [158, 180, 0];
//     case 'silo':
//       return [180, 158, 0];
//     case 'office':
//       return [0, 180, 158];
//     case 'farm_auxiliary':
//       return [158, 0, 180];
//     case 'parking':
//       return [0, 158, 180];
//     case 'cathedral':
//       return [180, 0, 158];
//     case 'grandstand':
//       return [158, 225, 0];
//     case 'semidetached_house':
//       return [225, 158, 0];
//     case 'kiosk':
//       return [0, 225, 158];
//     case 'garage':
//       return [158, 0, 225];
//     case 'school':
//       return [0, 158, 225];
//     case 'public':
//       return [225, 0, 158];
//     case 'carport':
//       return [158, 45, 90];
//     case 'house':
//       return [45, 158, 90];
//     case 'university':
//       return [90, 45, 158];
//     case 'terrace':
//       return [158, 90, 45];
//     case 'warehouse':
//       return [90, 158, 45];
//     case 'sports_hall':
//       return [45, 90, 158];
//     case 'tank':
//       return [158, 135, 90];
//     case 'kindergarten':
//       return [135, 158, 90];
//     case 'bunker':
//       return [90, 135, 158];
//     case 'train_station':
//       return [158, 90, 135];
//     case 'gatehouse':
//       return [90, 158, 135];
//     case 'religious':
//       return [135, 90, 158];
//     case 'hotel':
//       return [158, 180, 135];
//     case 'temple':
//       return [180, 158, 135];
//     case 'greenhouse':
//       return [135, 180, 158];
//     case 'synagogue':
//       return [158, 135, 180];
//     case 'transportation':
//       return [135, 158, 180];
//     case 'mosque':
//       return [180, 135, 158];
//     case 'hut':
//       return [158, 225, 180];
//     case 'ruins':
//       return [225, 158, 180];
//     case 'hospital':
//       return [180, 225, 158];
//     case 'retail':
//       return [158, 180, 225];
//     case 'chapel':
//       return [180, 158, 225];
//     case 'commercial':
//       return [225, 180, 158];
//     case 'college':
//       return [158, 45, 180];
//     case 'guardhouse':
//       return [45, 158, 180];
//     case 'government':
//       return [180, 45, 158];
//     case 'water_tower':
//       return [158, 180, 45];
//     case 'fire_station':
//       return [180, 158, 45];
//     case 'shop':
//       return [45, 180, 158];
//     case 'roof':
//       return [158, 225, 45];
//     default:
//       return [190, 150, 171];
//   }
// };

// export const legengdaMocks = [
//   {
//     label: 'Школы',
//     value: 'school',
//     id: 1,
//     color: [45, 180, 158],
//   },
//   {
//     label: 'Детские сады',
//     value: 'kindergarten',
//     id: 2,
//     color: [45, 180, 158],
//   },
//   {
//     label: 'Офисы',
//     value: 'office',
//     id: 3,
//     color: [45, 180, 158],
//   },
//   {
//     label: 'Больницы',
//     value: 'hospital',
//     id: 4,
//     color: [45, 180, 158],
//   },
//   {
//     label: 'Церкви',
//     value: 'church',
//     id: 5,
//     color: [45, 180, 158],
//   },
// ];

export const stylesForIzhs = [
  {
    id: 1,
    color: '#CA06EA',
  },
  {
    id: 2,
    color: '#FFD600',
  },
  {
    id: 3,
    color: '#E8062E',
  },
  {
    id: 4,
    color: '#48B7FF',
  },
  {
    id: 5,
    color: '#004D7E',
  },
  {
    id: 6,
    color: '#228300',
  },
];
