import { IconClose } from '@brusnika.tech/ui-icons';
import { IconButton, Select } from '@brusnika.tech/ui-kit';
import { Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { SyntheticEvent, useEffect, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { resetLayerMetaInfo } from '../store/slices/layerMetaInfoSlice';

const LayerMetaPopup = () => {
  const dispatch = useAppDispatch();
  const layerMetaInfo = useSelector((state: RootState) => state.layerMetaInfo);
  const [selectedValue, setSelectedValue] = useState<any | null>(null);
  const onChangeHandler = (_event: SyntheticEvent, value: any | null) => {
    setSelectedValue(value);
  };

  useEffect(() => {
    setSelectedValue(
      layerMetaInfo.layerMeta.find(
        (x) => x.meta_value === layerMetaInfo.metaValue,
      ) || layerMetaInfo.layerMeta[0],
    );
  }, [layerMetaInfo]);

  // useEffect(() => {
  //   console.log('layerMetaInfo', layerMetaInfo);
  // }, [layerMetaInfo]);

  return (
    <>
      {layerMetaInfo.layerMeta.length > 0 && (
        <Stack
          sx={{
            width: 264,
            padding: 2,
            background: '#fff',
            position: 'absolute',
            bottom: 0,
            right: 0,
            overflow: 'auto',
            maxHeight: 392,
            zIndex: 1,
            borderRadius: 1,
          }}
        >
          <Stack
            flexDirection={'row'}
            alignItems={'center'}
            justifyContent={'space-between'}
            marginBottom={3}
          >
            {selectedValue && (
              <Typography
                fontWeight={500}
                sx={{ fontSize: 12, color: '#14191A' }}
              >
                {selectedValue.meta_value}
              </Typography>
            )}
            <IconButton
              onClick={() => {
                dispatch(resetLayerMetaInfo());
              }}
              size="small"
            >
              <IconClose />
            </IconButton>
          </Stack>
          <Stack flexDirection={'row'} alignItems={'center'} marginBottom={3}>
            <Typography sx={{ fontSize: 11, color: '#14191A', marginRight: 2 }}>
              Слой
            </Typography>
            {selectedValue && (
              <Select
                fullWidth
                value={selectedValue}
                onChange={onChangeHandler}
                isOptionEqualToValue={(option: any, value: any) =>
                  option.meta_value === value.meta_value
                }
                getOptionLabel={(option: any) => option.meta_value}
                options={layerMetaInfo.layerMeta}
                // autoComplete
              />
            )}
          </Stack>
          {selectedValue &&
            Object.keys(selectedValue).map((key) => {
              if (
                key !== 'geometry' &&
                key !== 'contour' &&
                key !== 'center' &&
                key !== 'meta_title' &&
                key !== 'meta_value' &&
                key !== 'geom' &&
                key !== 'geo'
              ) {
                return (
                  <Stack sx={{ marginBottom: 2 }} key={key}>
                    <Typography sx={{ fontSize: 11, color: '#A8A19A' }}>
                      {key}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: 11,
                        color: '#14191A',
                        wordWrap: 'break-word',
                      }}
                    >
                      {selectedValue[key] || '-'}
                    </Typography>
                  </Stack>
                );
              }
              return null;
            })}
        </Stack>
      )}
    </>
  );
};

export default LayerMetaPopup;
