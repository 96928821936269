import { IconArrowDown, IconArrowRight } from '@brusnika.tech/ui-icons';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@brusnika.tech/ui-kit';
import { Box, Collapse, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { oksOsmCotroller } from '../../common/layersControllers';
import { useAppDispatch } from '../../hooks';
import { setActiveLayer } from '../store/slices/activeLayersManager';
import { setOksOsmLayer } from '../store/slices/tileLayerSlice';

const OksOsm = () => {
  const dispatch = useAppDispatch();
  const [showLayers, setShowLayers] = useState(false);
  const [isAllLayersSelected, setIsAllLayersSelected] = useState(false);
  const [selectedLayers, setSelectedLayers] = useState<any>(() => {
    const storedLayers = localStorage.getItem('oksOsmLayers');
    return storedLayers
      ? JSON.parse(storedLayers)
      : {
          all: false,
        };
  });

  // эта функция отвечает за выбор только одного чекбокса (функционал скрыт)
  // const [selectedLayers, setSelectedLayers] = useState<any>({
  //   all: false,
  //   allZu: false,
  //   allInZone: false,
  //   allZuInZone: false,
  // });

  // const handleSelectedLayerChange = (value: string) => {
  //   const newSelectedLayers = { ...selectedLayers };

  //   if (newSelectedLayers[value]) {
  //     newSelectedLayers[value] = false;
  //   } else {
  //     Object.keys(newSelectedLayers).forEach((key) => {
  //       newSelectedLayers[key] = key === value;
  //     });
  //   }

  //   setSelectedLayers(newSelectedLayers);
  // };

  useEffect(() => {
    setIsAllLayersSelected(
      Object.values(selectedLayers).some((value) => value),
    );
  }, [selectedLayers]);

  useEffect(() => {
    localStorage.setItem('oksOsmLayers', JSON.stringify(selectedLayers));
    oksOsmCotroller.map((item) => {
      dispatch(
        setActiveLayer({
          name: 'ОКС (OSM)',
          layer: [
            {
              label: item.label,
              isActive: selectedLayers[item.localValue],
              value: item.value,
              id: +item.id,
              background: item.background,
              border: item.borderColor,
              metaValue: 'ОСМ_Здания',
            },
          ],
        }),
      );
      dispatch(
        setOksOsmLayer({
          value: item.value,
          isActive: selectedLayers[item.localValue],
        }),
      );
    });
  }, [selectedLayers]);

  return (
    <>
      <Stack flexDirection={'row'} alignItems={'center'}>
        <IconButton
          sx={{ width: 16, height: 16 }}
          onClick={() => {
            setShowLayers(!showLayers);
          }}
        >
          {showLayers ? (
            <IconArrowDown iconSize="medium" />
          ) : (
            <IconArrowRight iconSize="medium" />
          )}
        </IconButton>
        <FormControlLabel
          sx={{ paddingLeft: 2 }}
          control={
            <Checkbox
              checked={isAllLayersSelected}
              onChange={(e) => {
                // if (!e.target.checked) {
                setSelectedLayers({
                  all: e.target.checked,
                  // allZu: e.target.checked,
                  // allInZone: e.target.checked,
                  // allZuInZone: e.target.checked,
                });
                // } else {
                //   // handleSelectedLayerChange('all');
                // }
              }}
            />
          }
          label={'ОКС (OSM)'}
        />
      </Stack>
      <Collapse in={showLayers}>
        <FormGroup sx={{ paddingLeft: 12 }}>
          {oksOsmCotroller.map((item) => {
            return (
              <FormControlLabel
                key={item.id}
                control={
                  <Stack flexDirection={'row'} alignItems={'center'}>
                    <Checkbox
                      checked={selectedLayers[item.localValue]}
                      onChange={(e) => {
                        // handleSelectedLayerChange(item.value);
                        setSelectedLayers({
                          ...selectedLayers,
                          [item.localValue]: e.target.checked,
                        });
                      }}
                    />
                    <Box
                      sx={{
                        width: 16,
                        height: 16,
                        borderRadius: 1,
                        marginLeft: 2,
                        whiteSpace: 'nowrap',
                        background: 'rgba(123, 3, 166, 0.2)',
                        border: '1px solid #7B03A6',
                      }}
                    />
                  </Stack>
                }
                size="small"
                label={item.label}
              />
            );
          })}
        </FormGroup>
      </Collapse>
    </>
  );
};

export default OksOsm;
