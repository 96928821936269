import { createSlice } from "@reduxjs/toolkit";
import { Krt } from "../../../types/krts";

const initialState: Krt = {
  id: "",
  code: "",
  territoryId: "",
  centroid: {
    type: "",
    coordinates: [],
  },
  geometry: {
    type: "",
    coordinates: [],
  },
};

const krtSlice = createSlice({
  name: "krtSlice",
  initialState: initialState,
  reducers: {
    setKrt: (state, action) => {
      state.code = action.payload.code;
      state.geometry = action.payload.geometry;
      state.territoryId = action.payload.territoryId;
      state.id = action.payload.id;
    },
    resetKrt: (state) => {
      state.code = "";
      state.geometry = {
        type: "",
        coordinates: [],
      };
      state.territoryId = "";
      state.id = "";
    },
  },
});

export const { setKrt, resetKrt } = krtSlice.actions;
export const { reducer: krtReducer } = krtSlice;
