import { IconMap } from '@brusnika.tech/ui-icons';
import { Button, MultiCombobox, Search } from '@brusnika.tech/ui-kit';
import { ToolBar } from '@brusnika.tech/ui-portal';
import { useRef } from 'react';
import { tradingMultiboxOptions } from '../../common/constants';
import { Stack } from '@mui/material';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useAppDispatch } from '../../hooks';
import {
  setFilterOption,
  setSearchValue,
} from '../store/slices/tableSettingsSlice';
import { useParams } from 'react-router-dom';

type TradingToolbarProps = {
  onClick: () => void;
  trades: any;
};

const TradingToolbar = ({ onClick, trades }: TradingToolbarProps) => {
  const dispatch = useAppDispatch();
  const ref = useRef<any>(null);
  const { card }: { card?: string } = useParams();
  const tableSettings = useSelector((state: RootState) => state.tableSetting);

  const searchOpts = {
    placeholder: 'Поиск по КРТ',
  };

  const multiComboboxOpts = {
    placeholder: 'Выберите',
  };

  return (
    <ToolBar
      leftContent={
        <Stack flexDirection={'row'} gap={6}>
          {!card && (
            <Search
              {...searchOpts}
              ref={ref}
              inputValue={tableSettings.searchValue}
              onInputChange={(_, value) => {
                dispatch(setSearchValue(value.toLocaleLowerCase()));
              }}
              getOptionLabel={(option: any) => option?.krt?.code}
              sx={{ width: 240 }}
              options={trades}
            />
          )}
          {!card && (
            <MultiCombobox
              {...multiComboboxOpts}
              sx={{ width: 200 }}
              value={tableSettings.filterOptions}
              noOptionsText="Не найдено"
              onChange={(e, value) => {
                dispatch(setFilterOption(value));
              }}
              options={tradingMultiboxOptions}
              // groupBy={(option) => option.group}
              noTags
            />
          )}
        </Stack>
      }
      rightContent={
        <Button
          onClick={onClick}
          sx={{
            background: 'rgba(20, 25, 26, 0.12)',
            color: '#14191A',
          }}
          children={'Карта'}
          variant="clean"
          startIcon={<IconMap />}
        />
      }
    />
  );
};

export default TradingToolbar;
