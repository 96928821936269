import { createSlice } from '@reduxjs/toolkit';
import { Territory } from '../../../types/territories';

const initialState: Territory = {
  properties: {
    id: '',
    code: '',
    priority: '',
    name: '',
    idPlatformISUPGround: '',
    krts: [
      {
        centroid: {
          type: '',
          coordinates: [],
        },
        code: '',
        geometry: {
          type: '',
          coordinates: [],
        },
        id: '',
        territoryId: '',
      },
    ],
    center: {
      type: '',
      coordinates: [],
    },
  },
  geometry: {
    type: '',
    coordinates: undefined,
  },
};

const territorySlice = createSlice({
  name: 'territorySlice',
  initialState: initialState,
  reducers: {
    setTerritory: (state, action) => {
      state.properties = action.payload.properties;
      state.geometry = action.payload.geometry;
    },
  },
});

export const { setTerritory } = territorySlice.actions;
export const { reducer: territoryReducer } = territorySlice;
