import { Content, Drawer, Module, useLayout } from '@brusnika.tech/ui-portal';
import TradingToolbar from '../../toolBars/TradingToolbar';
import MainHeader from '../../headers/MainHeader';
import Sidebar from '../../sidebar/Sidebar';
import MonitoringRightSidebar from '../../rightSideBarContent/MonitoringRightSidebar';
import { useEffect, useState } from 'react';
import MonitoringTable from '../../tables/MonitoringTable';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import {
  useGetAllTradesQuery,
  useGetTradesByKrtQuery,
  useGetTradesByTerritoryQuery,
} from '../../store/services/trades';
import { useParams } from 'react-router-dom';
import TradingKrtInfoCard from '../../tradingKrtInfoCard/TradingKrtInfoCard';
import Seo from '../../seo/Seo';
import {
  useGetTerritoryKrtQuery,
  useGetTerritoryQuery,
} from '../../store/services/territories';

const TradingMonitoringPage = () => {
  const { toggleRightDrawer, setRightDrawerWidth } = useLayout();
  const [overlapRightSidebar, setOverlapRightSidebar] = useState(true);
  const [headTitle, setHeadTitle] = useState('');
  const { id }: { id?: string } = useParams();
  const { krtId }: { krtId?: string } = useParams();
  const params = useParams();

  const { data: territory, isSuccess } = useGetTerritoryQuery(
    { territoryId: id! },
    { skip: !id },
  );
  const tradeInfo = useSelector((state: RootState) => state.tradingInfo);
  const tableSettings = useSelector((state: RootState) => state.tableSetting);

  const { data: trades } = useGetTradesByTerritoryQuery(
    {
      id: territory?.properties?.id!,
    },
    { skip: !territory?.properties?.id },
  );

  const { data: tradesByKrt } = useGetTradesByKrtQuery(
    {
      id: params.krtId! || tradeInfo.krtId,
    },
    { skip: !params.krtId! && !tradeInfo.krtId },
  );
  const { data: allTrades } = useGetAllTradesQuery({});

  const { data: krt, isSuccess: successKrt } = useGetTerritoryKrtQuery(
    { krtId: krtId! },
    { skip: !krtId },
  );

  const getTrades = () => {
    if (Object.keys(params).length === 0) {
      return allTrades;
    } else if (trades && params.id && !params.krtId) {
      return trades;
    } else if (tradesByKrt && params.krtId) {
      return tradesByKrt;
    } else if (tradesByKrt && params.card) {
      return tradesByKrt;
    }
  };

  useEffect(() => {
    if (!id) {
      setHeadTitle('Мониторинг торгов');
    } else if (territory && !krtId && isSuccess && !successKrt) {
      setHeadTitle(territory.properties.name);
    } else if (krt && successKrt && isSuccess) {
      setHeadTitle(`${territory?.properties.name} - ${krt.code}`);
    }
  }, [id, territory, krtId, krt]);

  useEffect(() => {
    setRightDrawerWidth(480);
  }, []);

  return (
    <Module
      leftSidebar={
        <Drawer resizable>
          <Sidebar />
        </Drawer>
      }
    >
      <>
        <Seo title={headTitle} />
        <Content
          header={<MainHeader />}
          toolbar={
            <TradingToolbar
              trades={getTrades()}
              onClick={() => {
                setOverlapRightSidebar(true);
                toggleRightDrawer();
              }}
            />
          }
          rightSidebar={
            <MonitoringRightSidebar
              trades={getTrades()}
              overlapRightSidebar={overlapRightSidebar}
              setOverlapRightSidebar={setOverlapRightSidebar}
            />
          }
        >
          {params?.card ? (
            <TradingKrtInfoCard />
          ) : (
            <>
              {tableSettings.filterOptions.length > 0 ? (
                <MonitoringTable
                  trades={getTrades()
                    ?.filter((x: any) =>
                      x?.krt?.code
                        .toLowerCase()
                        .includes(tableSettings.searchValue),
                    )
                    .filter((x: any) =>
                      tableSettings.filterOptions.some((selectedValue) =>
                        x.lotStatus.includes(selectedValue.label),
                      ),
                    )}
                />
              ) : (
                <MonitoringTable
                  trades={getTrades()?.filter((x: any) =>
                    x?.krt?.code
                      .toLowerCase()
                      .includes(tableSettings.searchValue),
                  )}
                />
              )}
            </>
          )}
        </Content>
      </>
    </Module>
  );
};

export default TradingMonitoringPage;
