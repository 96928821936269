import { useTheme } from '@emotion/react';
import { Stack, ListItemText } from '@mui/material';
import { useLocalStorageGeneric } from '@brusnika.tech/ui-kit';
import {} from '@brusnika.tech/ui-portal';
import { useState, useMemo, useEffect } from 'react';
import { NestedTreeProps } from '../types';
import { ControlledNestedTree } from '../ControlledNestedTree';
import { Territory } from '../../../types/territories';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { PATHS } from '../../navigation/paths';
import { Krt } from '../../../types/krts';
import { useAppDispatch } from '../../../hooks';
import { resetTradingKrtInfo } from '../../store/slices/tradingKrtInfo';
import { resetKrt } from '../../store/slices/krtSlice';

export interface BaseListItem<T = unknown> {
  id: string;
  children: BaseListItem<T>[];
  title: string;
  icon?: JSX.Element;
  myData?: Territory;
}

interface TreeItem extends BaseListItem {
  data?: { payload: string };
  geometry?: any;
  territoryId?: string;
  code?: string;
}

export const NestedTree = <T extends BaseListItem>({
  name,
  ...props
}: NestedTreeProps<T>): JSX.Element => {
  const [openedIds, setOpenedIds] = useLocalStorageGeneric<string[]>(name, []);

  return (
    <ControlledNestedTree
      {...props}
      openedIds={openedIds}
      setOpenedIds={setOpenedIds}
    />
  );
};

type SidebarTreeProps = {
  territories: Territory[];
};

const SidebarTree = ({ territories }: SidebarTreeProps) => {
  const [selectedId, setSelectedId] = useState<string>();
  const { id }: { id?: string } = useParams();
  const { krtId }: { krtId?: string } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (krtId) {
      const territory = territories.find((x) => x.properties.id === id);
      const territoryKrt = territory?.properties.krts.find(
        (x) => x.id === krtId,
      );
      setSelectedId(territoryKrt?.id);
    } else {
      const territory = territories.find((x) => x.properties.id === id);
      setSelectedId(territory?.properties.name);
    }
  }, [krtId]);

  const folders = useMemo(() => {
    const createItem = (krt: Krt): TreeItem => ({
      id: krt.id,
      title: krt.code,
      code: krt.code,
      territoryId: krt.territoryId,
      children: [],
      data: { payload: `item ${krt.code}` },
      geometry: krt.geometry,
    });

    const createFolder = (
      text: string,
      dataItem?: any,
      ...children: TreeItem[]
    ): TreeItem => ({
      id: text,
      title: text,
      children: children,
      myData: dataItem,
      data: { payload: `folder ${text}` },
    });

    const createdFolders =
      territories &&
      territories.map((dataItem: any) =>
        createFolder(
          dataItem?.properties?.name,
          dataItem,
          ...dataItem.properties.krts.map((krt: Krt) => createItem(krt)),
        ),
      );

    const rootFolder = createFolder('root');

    const foldersArray = createdFolders ? [...createdFolders] : [];

    return createFolder('root', rootFolder, ...foldersArray);
  }, [theme, territories]);

  return (
    <NestedTree<any>
      name={'versionsTree'}
      item={folders}
      onItemClick={(item) => {
        dispatch(resetTradingKrtInfo());
        setSelectedId(item.id);
        if (item.code) {
          if (location.pathname.includes('krt')) {
            navigate(
              PATHS.TERRITORY_KRT.replace(':id', item.territoryId).replace(
                ':krtId',
                item.id,
              ),
            );
          } else {
            navigate(
              PATHS.TRADING_MONITORING_TERRITORY_KRT.replace(
                ':id',
                item.territoryId,
              ).replace(':krtId', item.id),
            );
          }
        }
        if (item.myData) {
          dispatch(resetKrt());
          if (location.pathname.includes('krt')) {
            navigate(
              PATHS.KRT_TERRITORY.replace(':id', item.myData?.properties.id),
            );
          } else {
            navigate(
              PATHS.TRADING_MONITORING_TERRITORY.replace(
                ':id',
                item.myData?.properties.id,
              ),
            );
          }
        }
      }}
      itemContent={(item) => (
        <Stack
          paddingLeft={0}
          gap={0}
          flexDirection={'row'}
          alignItems={'center'}
        >
          {/* <ListItemIcon>{item.icon}</ListItemIcon> */}
          <Stack>
            <ListItemText
              sx={{ whiteSpace: 'pre-wrap' }}
              primary={item.title}
            />
            {item.myData && (
              <ListItemText
                sx={{ whiteSpace: 'pre-wrap', color: '#8A8C8C' }}
                primary={item?.myData?.properties?.priority}
              />
            )}
          </Stack>
        </Stack>
      )}
      isSelected={(item) => selectedId === item.id}
    />
  );
};

export default SidebarTree;
