import DeckGL from '@deck.gl/react';
import { Map } from 'react-map-gl';
import {
  accessToken,
  // getFillBuildingsColor,
  // mapStyle,
} from '../../common/constants';
import { GeoJsonLayer, PolygonLayer, TextLayer } from '@deck.gl/layers';
import {
  useGetAllKrtsQuery,
  useGetTerritoriesQuery,
  useGetTerritoryKrtQuery,
  useGetTerritoryQuery,
} from '../store/services/territories';
import { IconButton } from '@brusnika.tech/ui-kit';
import {
  IconAdd,
  IconArrowLeft,
  IconArrowRight,
  IconCollapse,
  IconExpand,
  IconLayers,
  IconRemove,
} from '@brusnika.tech/ui-icons';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import { FlyToInterpolator } from '@deck.gl/core';
import { useAppDispatch } from '../../hooks';
import { setKrt } from '../store/slices/krtSlice';
import { RootState } from '../store/store';
import { useSelector } from 'react-redux';
import { setTradingKrtInfo } from '../store/slices/tradingKrtInfo';
import { MVTLayer } from '@deck.gl/geo-layers';
import { Feature, Geometry } from 'geojson';
import {
  IzhsLayerPropertiesType,
  MingkhPropertiesType,
  NspdLayerPropertiesType,
  OksOsmTypes,
} from '../../types/buildings';
import { useLayout } from '@brusnika.tech/ui-portal';
import {
  useGetAllLayerStylesQuery,
  useGetLayerMetaQuery,
} from '../store/services/layerStyles';
import { setLayerMetaInfo } from '../store/slices/layerMetaInfoSlice';

type Coord = {
  latitude: number;
  longitude: number;
};

const KrtMap = () => {
  const dispatch = useAppDispatch();
  const { toggleLeftDrawer, openedLeftDrawer, toggleRightDrawer } = useLayout();
  const { id }: { id?: string } = useParams();
  const { krtId }: { krtId?: string } = useParams();
  const layerController = localStorage.getItem('layerController') === 'true';
  const { data: territory } = useGetTerritoryQuery(
    {
      territoryId: id!,
    },
    { skip: !id },
  );

  const [clickCoordinates, setClickCoordinates] = useState<Coord | null>(null);
  const activeLayerManager = useSelector(
    (state: RootState) => state.activeLayers,
  );

  const { data: layerMeta } = useGetLayerMetaQuery(
    {
      latitude: clickCoordinates?.latitude!,
      longitude: clickCoordinates?.longitude!,
      layerName: activeLayerManager
        .flatMap((item) =>
          item.layer.map((layerItem) => `&layerName=${layerItem.metaValue}`),
        )
        .join(''),
    },
    { skip: !clickCoordinates || activeLayerManager.length === 0 },
  );

  const mapSettings = useSelector((state: RootState) => state.mapSetting);
  const buildingLayer = useSelector((state: RootState) => state.buildingLayer);
  const tileLayer = useSelector((state: RootState) => state.tileLayer);
  const { data: layerStyles } = useGetAllLayerStylesQuery();
  const [nspdLayerUrl, setNspdLayerUrl] = useState('');
  const [izhsLayerUrl, setIzhsLayerUrl] = useState('');
  const [mingkhUrl, setMingkhUrl] = useState('');
  const [oksOsmUrl, setOksOsmUrl] = useState('');
  const [metaValue, setMetaValue] = useState('');

  useEffect(() => {
    if (layerMeta) {
      dispatch(
        setLayerMetaInfo({
          layerMeta: layerMeta,
          metaValue: metaValue,
        }),
      );
    }
  }, [layerMeta]);

  useEffect(() => {
    if (tileLayer.mingkhLayer.length > 0) {
      const floors = tileLayer.mingkhLayer
        .filter((item) => typeof item.value === 'number')
        .map((item) => item.value);
      const layers = tileLayer.mingkhLayer
        .filter((item) => typeof item.value === 'string')
        .map((item) => `${item.value}=Да`)
        .join('&');

      setMingkhUrl(
        `https://martin.brusnika.devcontour.ru/function_zxy_query_mkd/{z}/{x}/{y}?floors=[${floors}]&${layers}`,
      );
    } else {
      setMingkhUrl('');
    }
  }, [tileLayer, mingkhUrl]);

  useEffect(() => {
    if (tileLayer.nspdLayer.length > 0) {
      const values = tileLayer.nspdLayer.map((item) => `"${item.value}"`);
      setNspdLayerUrl(
        `https://martin.brusnika.devcontour.ru/function_zxy_query_nspd/{z}/{x}/{y}?predicted_permit=[${values}]`,
      );
    } else {
      setNspdLayerUrl('');
    }
  }, [tileLayer, nspdLayerUrl]);

  useEffect(() => {
    if (tileLayer.izhsLayer.length > 0) {
      const values = tileLayer.izhsLayer
        .map((item, index) => `total_area_${index + 1}=[${item.value}]`)
        .join('&');
      setIzhsLayerUrl(
        `https://martin.brusnika.devcontour.ru/function_zxy_query_izhs/{z}/{x}/{y}?${values}`,
      );
    } else {
      setIzhsLayerUrl('');
    }
  }, [tileLayer, izhsLayerUrl]);

  useEffect(() => {
    if (tileLayer.oksOsm.length > 0) {
      setOksOsmUrl(
        'https://martin.brusnika.devcontour.ru/function_zxy_query_osm_buildings/{z}/{x}/{y}',
      );
    } else {
      setOksOsmUrl('');
    }
  }, [tileLayer, oksOsmUrl]);

  const { data: krt } = useGetTerritoryKrtQuery(
    {
      krtId: krtId!,
    },
    { skip: !krtId },
  );

  const { data: AllKrts } = useGetAllKrtsQuery();

  useEffect(() => {
    if (krt) {
      dispatch(setKrt(krt));
    }
  }, [krt]);

  const [zoom, setZoom] = useState(11);
  const [mapWidth, setMapWidth] = useState(0);

  const territoryLayer = activeLayerManager.find(
    (x) => x.layer.find((x) => x.value === 'Граница_НП')?.isActive,
  )
    ? new GeoJsonLayer({
        id: 'territoryLayer',
        data: territory && (territory.geometry as any),
        lineWidthMinPixels: 2,
        lineWidthScale: 1,
        filled: false,
        getLineColor: [187, 0, 0],
        pickable: true,
        onClick(pickingInfo, event) {
          console.log('pickingInfo.object', pickingInfo.object);
          // setLayerName(pickingInfo.object.properties.predicted_permit);
        },
      })
    : null;

  const polygonsLayer = new GeoJsonLayer({
    id: 'polygonsLayer',
    data: AllKrts && AllKrts,
    lineWidthMinPixels: 2,
    lineWidthScale: 1,
    filled: false,
  });

  const polygonsLayerText = new TextLayer({
    id: 'polygonsLayerText',
    data: AllKrts && AllKrts,
    getPosition: (item: any) => item.centroid.coordinates,
    getText: (item) => item.code,
    getSize: 13,
    sizeMaxPixels: 13,
    getTextAnchor: 'middle',
    sizeUnits: 'meters',
    getAlignmentBaseline: 'center',
    fontFamily: 'Arial',
    characterSet: 'auto',
  });

  const nspdLayer = new MVTLayer<NspdLayerPropertiesType>({
    id: 'nspdLayer',
    data: [nspdLayerUrl],
    onClick(pickingInfo, event) {
      console.log('pickingInfo.object', pickingInfo.object);
      setMetaValue(pickingInfo.object.properties.meta_value);
    },
    getFillColor: (f: Feature<Geometry, NspdLayerPropertiesType>) => {
      for (const key in layerStyles) {
        if (f.properties.predicted_permit === key) {
          return layerStyles[key].color;
        }
      }
    },
    getLineColor: (f: Feature<Geometry, NspdLayerPropertiesType>) => {
      for (const key in layerStyles) {
        if (f.properties.predicted_permit === key) {
          return layerStyles[key].stroke;
        }
      }
    },
    // updateTriggers: {
    //   getFillColor: buildingLayer,
    // },
    colorFormat: 'RGBA',
    debounceTime: 1000,
    getLineWidth: 1,
    // getPointRadius: 2,
    pointRadiusUnits: 'pixels',
    stroked: true,
    pickable: true,
  });

  const izhsLayer = new MVTLayer<IzhsLayerPropertiesType>({
    id: 'izhsLayer',
    data: [izhsLayerUrl],
    onClick(pickingInfo, event) {
      console.log('pickingInfo.object', pickingInfo.object);
      setMetaValue(pickingInfo.object.properties.meta_value);
      setClickCoordinates({
        latitude: pickingInfo.object.properties.latitude,
        longitude: pickingInfo.object.properties.longitude,
      });
    },
    getLineColor: (f: Feature<Geometry, IzhsLayerPropertiesType>) => {
      const hexToRgb = (hex: string) => {
        if (typeof hex !== 'string') {
          throw new TypeError('Expected a string');
        }
        hex = hex.replace(/^#/, '');
        let bigint = parseInt(hex, 16);
        let r = (bigint >> 16) & 255;
        let g = (bigint >> 8) & 255;
        let b = bigint & 255;
        return [r, g, b];
      };

      const customLayer = activeLayerManager
        .flatMap((manager) => manager.layer)
        .find(
          (layer) =>
            layer.customLayer &&
            f.properties.total_area >=
              Number(layer.value.toString().split(',')[0]) &&
            f.properties.total_area <=
              Number(layer.value.toString().split(',')[1]),
        );

      if (customLayer) {
        return hexToRgb(customLayer.background);
      } else {
        switch (true) {
          case f.properties.total_area < 50:
            return layerStyles.rangeUnder50.color;
          case f.properties.total_area >= 50 && f.properties.total_area < 150:
            return layerStyles.range50to150.color;
          case f.properties.total_area >= 150 && f.properties.total_area < 300:
            return layerStyles.range150to300.color;
          case f.properties.total_area >= 300:
            return layerStyles.rangeOver300.color;
          default:
            return [240, 240, 240];
        }
      }
    },
    updateTriggers: {
      getLineColor: activeLayerManager,
    },
    colorFormat: 'RGB',
    debounceTime: 1000,
    lineWidthScale: 5,
    pointRadiusUnits: 'pixels',
    pickable: true,
  });

  const mkdLayer = new MVTLayer<MingkhPropertiesType>({
    id: 'mkdLayer',
    data: [mingkhUrl],
    onClick(pickingInfo, event) {
      console.log('pickingInfo.object', pickingInfo.object);
      setMetaValue(pickingInfo.object.properties.meta_value);
      setClickCoordinates({
        latitude: pickingInfo.object.properties.latitude,
        longitude: pickingInfo.object.properties.longitude,
      });
    },
    getLineColor: (f: Feature<Geometry, MingkhPropertiesType>) => {
      if (f.properties.is_wreck) {
        return layerStyles.mkdEmergency.color;
      } else if (f.properties.built_at <= 1980 && +f.properties.floors <= 4) {
        if (f.properties.floors === '1') {
          return layerStyles.mkdCriteria1.color;
        } else if (f.properties.floors === '2') {
          return layerStyles.mkdCriteria2.color;
        } else if (f.properties.floors === '3') {
          return layerStyles.mkdCriteria3.color;
        } else if (f.properties.floors === '4') {
          return layerStyles.mkdCriteria4.color;
        }
      }
      return layerStyles.mkdOther.color;
    },
    colorFormat: 'RGB',
    debounceTime: 1000,
    lineWidthScale: 5,
    pointRadiusUnits: 'pixels',
    pickable: true,
  });

  const oksOsmLayer = new MVTLayer<OksOsmTypes>({
    id: 'oksOsmLayer',
    data: [oksOsmUrl],
    onClick(pickingInfo, event) {
      console.log('pickingInfo.object', pickingInfo.object);
      setMetaValue(pickingInfo.object.properties.meta_value);
    },
    getFillColor: (f: Feature<Geometry, OksOsmTypes>) =>
      layerStyles.OksOsmAllBuildings.color,
    getLineColor: (f: Feature<Geometry, OksOsmTypes>) =>
      layerStyles.OksOsmAllBuildings.stroke,
    // updateTriggers: {
    //   getFillColor: buildingLayer,
    // },
    colorFormat: 'RGBA',
    debounceTime: 1000,
    getLineWidth: 1,
    // getPointRadius: 2,
    pointRadiusUnits: 'pixels',
    stroked: true,
    pickable: true,
  });

  const [viewport, setViewport] = useState<any>({
    longitude: 37.603074966,
    latitude: 55.742085689,
    zoom: 11,
    width: 0,
  });

  const handleFlyTo = (longitude: any, latitude: any, zoom: number) => {
    setViewport({
      ...viewport,
      longitude,
      latitude,
      zoom: zoom,
      transitionDuration: 2000,
      transitionInterpolator: new FlyToInterpolator(),
    });
  };

  useEffect(() => {
    setViewport({
      ...viewport,
      width: mapWidth,
      transitionInterpolator: '',
    });
  }, [mapWidth]);

  useEffect(() => {
    if (territory && !krtId) {
      handleFlyTo(
        territory?.properties.center.coordinates[0],
        territory?.properties.center.coordinates[1],
        11,
      );
    }
  }, [territory, krtId]);

  useEffect(() => {
    if (krt) {
      handleFlyTo(
        krt?.centroid?.coordinates[0],
        krt?.centroid?.coordinates[1],
        16,
      );
    }
  }, [krt]);

  useEffect(() => {
    setViewport({
      ...viewport,
      zoom: zoom,
      transitionDuration: 300,
      transitionInterpolator: new FlyToInterpolator(),
    });
  }, [zoom]);

  return (
    <>
      {id && territory && (
        <DeckGL
          initialViewState={viewport}
          controller
          onResize={(d) => {
            setMapWidth(d.width);
          }}
          onClick={(info: any) => {
            if (!info.layer) {
              setMetaValue('Граница_НП');
            }
            if (
              info.coordinate &&
              activeLayerManager.length > 0 &&
              info.featureType !== 'points'
            ) {
              setClickCoordinates({
                latitude: info.coordinate[1],
                longitude: info.coordinate[0],
              });
            }
          }}
          getCursor={(state) => {
            if (state.isHovering) {
              return 'pointer';
            }
            return 'grab';
          }}
          style={{ position: 'relative', overflow: 'hidden' }}
          layers={[
            nspdLayer,
            territoryLayer,
            oksOsmLayer,
            izhsLayer,
            mkdLayer,
            // polygonsLayer,
            // polygonsLayerText,
          ]}
        >
          <Stack
            flexDirection={'row'}
            sx={{ position: 'absolute', top: 24, right: 24, gap: 2 }}
          >
            <IconButton
              onClick={() => {
                toggleRightDrawer();
                layerController
                  ? localStorage.setItem('layerController', 'false')
                  : localStorage.setItem('layerController', 'true');
              }}
              size="large"
              variant="outline"
            >
              <IconLayers />
            </IconButton>
            <IconButton
              onClick={toggleLeftDrawer}
              size="large"
              variant="outline"
            >
              {openedLeftDrawer ? <IconExpand /> : <IconCollapse />}
            </IconButton>
          </Stack>
          <Stack sx={{ position: 'absolute', bottom: 48, right: 24, gap: 2 }}>
            <IconButton
              onClick={() => {
                if (zoom < 20) {
                  setZoom(zoom + 1);
                }
              }}
              size="large"
              variant="outline"
            >
              <IconAdd iconSize="medium" />
            </IconButton>
            <IconButton
              onClick={() => {
                if (zoom > 1) {
                  setZoom(zoom - 1);
                }
              }}
              size="large"
              variant="outline"
            >
              <IconRemove iconSize="medium" />
            </IconButton>
          </Stack>
          {mapSettings.mapStyle && (
            <Map
              mapStyle={mapSettings.mapStyle}
              mapboxAccessToken={accessToken}
            />
          )}
        </DeckGL>
      )}
    </>
  );
};

export default KrtMap;
