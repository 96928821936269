import { Box, Typography } from '@mui/material';
import moment from 'moment';
import { useLayout } from '@brusnika.tech/ui-portal';
import { useGetTradeByIdQuery } from '../store/services/trades';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from '../../hooks';
import { useEffect } from 'react';
import { setTradingKrtInfo } from '../store/slices/tradingKrtInfo';

const TradingKrtInfoCard = () => {
  const dispatch = useAppDispatch();
  const { openedRightDrawer } = useLayout();
  const params = useParams();

  const { data: soloTrade } = useGetTradeByIdQuery(
    {
      id: params?.card,
    },
    { skip: !params?.card },
  );

  useEffect(() => {
    if (soloTrade) {
      dispatch(setTradingKrtInfo(soloTrade));
    }
  }, [soloTrade]);

  return (
    <Box
      sx={{ width: openedRightDrawer ? 'calc(100% - 480px)' : '100%' }}
      padding={'16px 24px'}
    >
      <Typography fontSize={11} sx={{ color: '#8A8C8C', marginBottom: 1 }}>
        Электронная площадка
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.platform || '-'}
      </Typography>
      <Typography fontSize={11} sx={{ color: '#8A8C8C' }}>
        Ссылка на извещение в ОЧ Реестра извещения
      </Typography>
      <a
        target="_blank"
        href={soloTrade?.noticesUrl}
        style={{ fontSize: 11, wordWrap: 'break-word' }}
      >
        {soloTrade?.noticesUrl || '-'}
      </a>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Дата и время начала подачи заявок
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {moment(soloTrade?.startRequestsDate).format('DD.MM.yyyy HH:mm') || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Дата и время начала аукциона
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {moment(soloTrade?.startAuctionDate).format('DD.MM.yyyy HH:mm') || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Наименование организации
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.organizationName || '-'}
      </Typography>
      <Typography fontSize={11} sx={{ color: '#8A8C8C' }}>
        Ссылка на КРТ
      </Typography>
      <a
        target="_blank"
        href={soloTrade?.noticesUrl}
        style={{ fontSize: 11, wordWrap: 'break-word' }}
      >
        {soloTrade?.krtUrl || '-'}
      </a>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Описание лота
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.lotDescription || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Предмет торгов
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.auctionSubject || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Текущий статус торгов (классификация)
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.auctionStatus || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Субъект РФ
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.subjectRF || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Начальная цена
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.startPrice || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Итоговая цена
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.finalPrice || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Площадь КРТ
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.krtArea || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Срок КРТ
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.krtPeriod || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Итог
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.summation || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Победитель
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.winner || '-'}
      </Typography>
      <Typography
        fontSize={11}
        sx={{ color: '#8A8C8C', marginTop: 2, marginBottom: 1 }}
      >
        Предпоследнее предложение
      </Typography>
      <Typography fontSize={11} sx={{ color: '#14191A', marginBottom: 2 }}>
        {soloTrade?.penultimateSentence || '-'}
      </Typography>
    </Box>
  );
};

export default TradingKrtInfoCard;
