import { PayloadAction, createSlice } from '@reduxjs/toolkit';

type SelectedLayer = {
  label: string;
  isActive: boolean;
  value: string | number;
  id: number;
  background: string;
  border?: string;
  metaValue?: string;
  customLayer?: boolean;
};

type ActiveLayerInfo = { name?: string; layer: SelectedLayer[] };

const initialState: ActiveLayerInfo[] = [];

const activeLayersManager = createSlice({
  name: 'activeLayersManager',
  initialState,
  reducers: {
    setActiveLayer: (state, action: PayloadAction<ActiveLayerInfo>) => {
      const { name, layer } = action.payload;

      const existingIndex = state.findIndex((info) => info.name === name);

      if (existingIndex !== -1) {
        const existingLayers = state[existingIndex].layer;
        const updatedLayers = [...existingLayers];

        layer.forEach((layerItem) => {
          if (layerItem.isActive) {
            const existingItemIndex = updatedLayers.findIndex(
              (item) => item.id === layerItem.id,
            );
            if (existingItemIndex !== -1) {
              updatedLayers.splice(existingItemIndex, 1, layerItem); // Заменяем старый объект на новый
            } else {
              updatedLayers.push(layerItem);
            }
          } else {
            const removeIndex = updatedLayers.findIndex(
              (item) => item.id === layerItem.id,
            );
            if (removeIndex !== -1) {
              updatedLayers.splice(removeIndex, 1);
            }
          }
        });

        if (updatedLayers.length > 0) {
          state[existingIndex].layer = updatedLayers;
        } else {
          state.splice(existingIndex, 1); // Удаляем объект, если layers пустой
        }
      } else {
        const newLayers: SelectedLayer[] = [];

        layer.forEach((layerItem) => {
          if (layerItem.isActive) {
            newLayers.push(layerItem);
          }
        });

        if (newLayers.length > 0) {
          state.push({ name, layer: newLayers });
        }
      }
    },
  },
});

export const { setActiveLayer } = activeLayersManager.actions;
export const { reducer: activeLayersManagerReducer } = activeLayersManager;
