export const PATHS = {
  KRT: '/krt',
  KRT_TERRITORY: '/krt/:id',
  TERRITORY_KRT: '/krt/:id/:krtId',

  TRADING_MONITORING: '/trading-monitoring',
  TRADING_MONITORING_TERRITORY: '/trading-monitoring/:id',
  TRADING_MONITORING_TERRITORY_KRT: '/trading-monitoring/:id/:krtId',
  TRADING_MONITORING_CARD: '/trading-monitoring/trade/:card',
};
