import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type Layer = { value: string | number; isActive: boolean }[];

type TileLayerState = {
  nspdLayer: Layer;
  izhsLayer: Layer;
  mingkhLayer: Layer;
  oksOsm: Layer;
};

const initialState: TileLayerState = {
  nspdLayer: [],
  izhsLayer: [],
  mingkhLayer: [],
  oksOsm: [],
};

const tileLayerSlice = createSlice({
  name: 'tileLayerSlice',
  initialState,
  reducers: {
    setNspdLayer: (
      state,
      action: PayloadAction<{ value: string; isActive: boolean }>,
    ) => {
      const index = state.nspdLayer.findIndex(
        (layer) => layer.value === action.payload.value,
      );
      if (index !== -1 && !action.payload.isActive) {
        state.nspdLayer = state.nspdLayer.filter(
          (layer) => layer.value !== action.payload.value,
        );
      } else if (index === -1 && action.payload.isActive) {
        state.nspdLayer.push({
          value: action.payload.value,
          isActive: action.payload.isActive,
        });
      }
    },
    setIzhsLayer: (
      state,
      action: PayloadAction<{ value: string; isActive: boolean }>,
    ) => {
      const index = state.izhsLayer.findIndex(
        (layer) => layer.value === action.payload.value,
      );
      if (index !== -1 && !action.payload.isActive) {
        state.izhsLayer = state.izhsLayer.filter(
          (layer) => layer.value !== action.payload.value,
        );
      } else if (index === -1 && action.payload.isActive) {
        state.izhsLayer.push({
          value: action.payload.value,
          isActive: action.payload.isActive,
        });
      }
    },
    setMingkhLayer: (
      state,
      action: PayloadAction<{ value: string | number; isActive: boolean }>,
    ) => {
      const index = state.mingkhLayer.findIndex(
        (layer) => layer.value === action.payload.value,
      );
      if (index !== -1 && !action.payload.isActive) {
        state.mingkhLayer = state.mingkhLayer.filter(
          (layer) => layer.value !== action.payload.value,
        );
      } else if (index === -1 && action.payload.isActive) {
        state.mingkhLayer.push({
          value: action.payload.value,
          isActive: action.payload.isActive,
        });
      }
    },
    setOksOsmLayer: (
      state,
      action: PayloadAction<{ value: string | number; isActive: boolean }>,
    ) => {
      const index = state.oksOsm.findIndex(
        (layer) => layer.value === action.payload.value,
      );
      if (index !== -1 && !action.payload.isActive) {
        state.oksOsm = state.oksOsm.filter(
          (layer) => layer.value !== action.payload.value,
        );
      } else if (index === -1 && action.payload.isActive) {
        state.oksOsm.push({
          value: action.payload.value,
          isActive: action.payload.isActive,
        });
      }
    },
  },
});

export const { setNspdLayer, setIzhsLayer, setMingkhLayer, setOksOsmLayer } =
  tileLayerSlice.actions;
export const tileLayerReducer = tileLayerSlice.reducer;
