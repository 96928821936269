import { BaseListItem, ControlledNestedTreeProps } from './types';
import { useLayout } from '@brusnika.tech/ui-portal';
import { useCallback } from 'react';
import {
  Box,
  Collapse,
  IconButton,
  List,
  ListItemButton,
  Stack,
  SxProps,
  Theme,
  useTheme,
} from '@mui/material';
import { IconArrowDown, IconArrowRight } from '@brusnika.tech/ui-icons';

export function ControlledNestedTree<T extends BaseListItem>({
  item,
  level = 0,
  onItemClick,
  itemContent,
  isSelected,
  selectedRef,
  chevronPosition = 'left',
  chevronClosed,
  chevronOpened,
  paddingLeftMap,
  paddingRightMap,
  ...props
}: ControlledNestedTreeProps<T>): JSX.Element {
  const theme = useTheme();
  const { isDesktop } = useLayout();
  const isOpen = props.openedIds.includes(item.id);
  const isItemSelected = isSelected?.(item);

  const collapsible = item.children.length > 0;

  function getOpenedChildren<T extends BaseListItem>(
    items: T[],
    openIds: string[]
  ): string[] {
    let ids = items
      .filter(item => openIds.includes(item.id))
      .map(item => item.id);

    items.forEach(item => {
      if (item.children.length) {
        ids = ids.concat(getOpenedChildren(item.children, openIds));
      }
    });

    return ids;
  }

  const handleToggle = () => {
    if (collapsible) {
      if (isOpen) {
        const items = getOpenedChildren([item], props.openedIds);
        props.setOpenedIds(prevIds =>
          prevIds.filter(id => !items.includes(id))
        );
      } else {
        props.setOpenedIds(prevIds => prevIds.concat([item.id]));
      }
    }
  };

  const handleItemClick = () => {
    if (item && onItemClick) {
      onItemClick(item);
    }
  };

  const renderChildren = useCallback(
    (children: BaseListItem[]) =>
      children.map(child => (
        <ControlledNestedTree<T>
          chevronClosed={chevronClosed}
          chevronOpened={chevronOpened}
          chevronPosition={chevronPosition}
          isSelected={isSelected}
          item={child as T}
          itemContent={itemContent}
          key={child.id}
          level={level + 1}
          openedIds={props.openedIds}
          paddingLeftMap={paddingLeftMap}
          paddingRightMap={paddingRightMap}
          selectedRef={selectedRef}
          setOpenedIds={props.setOpenedIds}
          onItemClick={onItemClick}
        />
      )),
    [
      level,
      onItemClick,
      itemContent,
      isSelected,
      props.openedIds,
      props.setOpenedIds,
      selectedRef,
    ]
  );

  if (!item) {
    return <></>;
  }

  if (level === 0) {
    return <List disablePadding>{renderChildren(item.children)}</List>;
  }

  const chevron = collapsible ? (
    <IconButton
      size="small"
      onClick={() => {
        handleToggle();
      }}
    >
      {isOpen
        ? chevronOpened ?? <IconArrowDown iconSize="medium" />
        : chevronClosed ?? <IconArrowRight iconSize="medium" />}
    </IconButton>
  ) : (
    <Box />
  );

  const paddedSx: SxProps<Theme> = {
    position: 'relative',
    paddingLeft:
      paddingLeftMap?.(item, theme, level, isDesktop) ??
      theme.spacing((isDesktop ? 3 : 4) + 6 * (level - 1)),
    paddingRight:
      paddingRightMap?.(item, theme, level, isDesktop) ??
      theme.spacing(isDesktop ? 3 : 3),
  };

  return (
    <>
      <ListItemButton
        ref={isItemSelected ? selectedRef : null}
        selected={isItemSelected}
        sx={paddedSx}
      >
        <Stack
          alignItems="center"
          direction={chevronPosition === 'right' ? 'row-reverse' : 'row'}
          flex="1"
          gap={2}
          sx={{ paddingLeft: 6 }}
          justifyContent={
            chevronPosition === 'right' ? 'space-between' : 'start'
          }
        >
          {chevronPosition !== 'hidden' && chevron}
          <div
            style={{ width: '100%' }}
            onClick={() => {
              handleItemClick();
            }}
          >
            {itemContent?.(item) ?? null}
          </div>
        </Stack>
      </ListItemButton>

      {collapsible && (
        <Collapse unmountOnExit in={isOpen} timeout="auto">
          {renderChildren(item.children)}
        </Collapse>
      )}
    </>
  );
}
