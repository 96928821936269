import { baseApi } from './baseApi';

export const tradesApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getTradesByTerritory: builder.query<any, { id: string }>({
      query: ({ id }) => `/trades/territories/${id}`,
      providesTags: ['Trades'],
    }),
    getTradesByKrt: builder.query<any, { id: string }>({
      query: ({ id }) => `/trades/krt/${id}`,
      providesTags: ['Trades'],
    }),
    getTradeById: builder.query<any, { id: string | undefined }>({
      query: ({ id }) => `/trades/${id}`,
      providesTags: ['Trades'],
    }),
    getAllTrades: builder.query<any, any>({
      query: () => `/trades`,
      providesTags: ['Trades'],
    }),
  }),
});

export const {
  useGetTradesByKrtQuery,
  useGetTradesByTerritoryQuery,
  useGetAllTradesQuery,
  useGetTradeByIdQuery,
} = tradesApi;
