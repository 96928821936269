import {
  AppBar,
  defaultProjectServicesOptions,
  DrawerProvider,
  Layout,
  UserProfileBar,
} from '@brusnika.tech/ui-portal';
import { PropsWithChildren, ReactElement } from 'react';
import { projectConfig } from './project-config';
import UserMenu from '../userMenu/UserMenu';

export type LayoutProps = {
  children: ReactElement;
};

const LayoutComponent = ({ children }: PropsWithChildren<LayoutProps>) => {
  return (
    <DrawerProvider defaultOpenedLeftDrawer defaultRightOverlaps>
      <Layout
        appBar={
          <AppBar
            name="Поиск и анализ земли"
            projectSelectOptions={defaultProjectServicesOptions}
            userProfile={<UserMenu />}
            navigationTree={projectConfig}
          />
        }
        children={children}
      />
    </DrawerProvider>
  );
};

export default LayoutComponent;
