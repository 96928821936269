import { IconArrowDown, IconArrowRight } from '@brusnika.tech/ui-icons';
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
} from '@brusnika.tech/ui-kit';
import { Box, Collapse, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { mingkhController } from '../../common/layersControllers';
import { setActiveLayer } from '../store/slices/activeLayersManager';
import { useAppDispatch } from '../../hooks';
import { setMingkhLayer } from '../store/slices/tileLayerSlice';

const MinGkhControl = () => {
  const dispatch = useAppDispatch();
  const [showLayers, setShowLayers] = useState(false);
  const [showCriteriaLayers, setShowCriteriaLayers] = useState(false);
  const [isAllLayersSelected, setIsAllLayersSelected] = useState(false);
  const [isAllСriteriaLayersSelected, setIsAllСriteriaLayersSelected] =
    useState(false);

  const [selectedLayers, setSelectedLayers] = useState<any>(() => {
    const storedLayers = localStorage.getItem('mingkhLayers');
    return storedLayers
      ? JSON.parse(storedLayers)
      : {
          emergency: false,
          criteria1: false,
          criteria2: false,
          criteria3: false,
          criteria4: false,
          other: false,
        };
  });

  useEffect(() => {
    setIsAllLayersSelected(
      Object.values(selectedLayers).some((value) => value),
    );
  }, [selectedLayers]);

  useEffect(() => {
    localStorage.setItem('mingkhLayers', JSON.stringify(selectedLayers));
    const layers = [
      {
        label: 'Аварийные',
        isActive: selectedLayers.emergency,
        value: 'wreck_filter',
        id: 1,
        background: '#CF0000',
        metaValue: 'МКД_Аварийные',
      },
      ...mingkhController.map((item) => ({
        label: item.label,
        isActive: selectedLayers[item.localValue],
        value: item.value,
        id: +item.id,
        background: item.background,
        metaValue: item.metaValue,
      })),
      {
        label: 'Прочие',
        isActive: selectedLayers.other,
        value: 'other',
        id: 6,
        background: '#7D7D7D',
        metaValue: 'МКД_Прочие',
      },
    ];

    layers.forEach((layer) => {
      dispatch(
        setActiveLayer({
          name: 'ОКС МКД (МинЖКХ)',
          layer: [layer],
        }),
      );
      dispatch(
        setMingkhLayer({
          value: layer.value,
          isActive: layer.isActive,
        }),
      );
    });
  }, [selectedLayers]);

  useEffect(() => {
    setIsAllСriteriaLayersSelected(
      selectedLayers.criteria1 ||
        selectedLayers.criteria2 ||
        selectedLayers.criteria3 ||
        selectedLayers.criteria4,
    );
  }, [selectedLayers]);

  return (
    <>
      <Stack flexDirection={'row'} alignItems={'center'}>
        <IconButton
          sx={{ width: 16, height: 16 }}
          onClick={() => {
            setShowLayers(!showLayers);
          }}
        >
          {showLayers ? (
            <IconArrowDown iconSize="medium" />
          ) : (
            <IconArrowRight iconSize="medium" />
          )}
        </IconButton>
        <FormControlLabel
          sx={{ paddingLeft: 2 }}
          control={
            <Checkbox
              checked={isAllLayersSelected}
              onChange={(e) => {
                setSelectedLayers({
                  emergency: e.target.checked,
                  criteria1: e.target.checked,
                  criteria2: e.target.checked,
                  criteria3: e.target.checked,
                  criteria4: e.target.checked,
                  other: e.target.checked,
                });
              }}
            />
          }
          label={'ОКС МКД (МинЖКХ)'}
        />
      </Stack>
      <Collapse in={showLayers}>
        <FormGroup>
          <FormControlLabel
            sx={{ paddingLeft: 12 }}
            control={
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Checkbox
                  checked={selectedLayers.emergency}
                  onChange={(e) => {
                    setSelectedLayers({
                      ...selectedLayers,
                      emergency: e.target.checked,
                    });
                  }}
                />
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    borderRadius: 1,
                    marginLeft: 2,
                    background: '#CF0000',
                  }}
                />
              </Stack>
            }
            size="small"
            label={'Аварийные'}
          />
          <Stack
            sx={{ paddingLeft: 6 }}
            flexDirection={'row'}
            alignItems={'center'}
          >
            <IconButton
              sx={{ width: 16, height: 16 }}
              onClick={() => {
                setShowCriteriaLayers(!showCriteriaLayers);
              }}
            >
              {showCriteriaLayers ? (
                <IconArrowDown iconSize="medium" />
              ) : (
                <IconArrowRight iconSize="medium" />
              )}
            </IconButton>
            <FormControlLabel
              sx={{ paddingLeft: 2 }}
              control={
                <Checkbox
                  checked={isAllСriteriaLayersSelected}
                  onChange={(e) => {
                    setSelectedLayers({
                      ...selectedLayers,
                      criteria1: e.target.checked,
                      criteria2: e.target.checked,
                      criteria3: e.target.checked,
                      criteria4: e.target.checked,
                    });
                  }}
                />
              }
              label={'Критериальные'}
              size="small"
            />
          </Stack>
          <Collapse in={showCriteriaLayers}>
            <FormGroup sx={{ paddingLeft: 18, marginBottom: 0 }}>
              {mingkhController.map((item) => {
                return (
                  <FormControlLabel
                    key={item.id}
                    control={
                      <Stack flexDirection={'row'} alignItems={'center'}>
                        <Checkbox
                          checked={selectedLayers[item.localValue]}
                          onChange={(e) => {
                            setSelectedLayers({
                              ...selectedLayers,
                              [item.localValue]: e.target.checked,
                            });
                          }}
                        />
                        <Box
                          sx={{
                            width: 16,
                            height: 16,
                            borderRadius: 1,
                            marginLeft: 2,
                            background: item.background,
                          }}
                        />
                      </Stack>
                    }
                    size="small"
                    label={item.label}
                  />
                );
              })}
            </FormGroup>
          </Collapse>
          <FormControlLabel
            sx={{ paddingLeft: 12 }}
            control={
              <Stack flexDirection={'row'} alignItems={'center'}>
                <Checkbox
                  checked={selectedLayers.other}
                  onChange={(e) => {
                    setSelectedLayers({
                      ...selectedLayers,
                      other: e.target.checked,
                    });
                  }}
                />
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    borderRadius: 1,
                    marginLeft: 2,
                    background: '#7D7D7D',
                  }}
                />
              </Stack>
            }
            size="small"
            label={'Прочие'}
          />
        </FormGroup>
      </Collapse>
    </>
  );
};

export default MinGkhControl;
