import { IconClose } from '@brusnika.tech/ui-icons';
import { Button, IconButton } from '@brusnika.tech/ui-kit';
import { Header } from '@brusnika.tech/ui-portal';
import { Box, Stack, TextField, Typography } from '@mui/material';
import { FC } from 'react';
import { SideSheetTestComponentProps } from './types';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import {
  useGetTerritorySettingsQuery,
  useSetTerritorySettingsMutation,
} from '../store/services/layerSettings';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { useGetCurrentUserQuery } from '../store/services/auth';

type LayersSettigsFormFields = {
  izhs_total_area: number | null;
  mkd_built_at: number | null;
  mkd_floors: number | null;
  price_izhs: number | null;
  price_oks_izhs: number | null;
  price_mkd: number | null;
  gradpotential: number | null;
};

const LayersSettigsSideSheet: FC<SideSheetTestComponentProps> = ({
  sideSheetManager,
  onChange,
}) => {
  const territoryId = useSelector(
    (state: RootState) => state.territory.properties.id,
  );

  const { data } = useGetCurrentUserQuery();

  const [setTerritorySettings] = useSetTerritorySettingsMutation();

  const { data: territorySettings } = useGetTerritorySettingsQuery(
    {
      territoryId: territoryId,
    },
    { skip: !territoryId },
  );

  const handleCloseSideSheet = () => {
    sideSheetManager.hide();
  };

  const {
    control,
    handleSubmit,
    formState: { isValid },
  } = useForm<LayersSettigsFormFields>({
    values: {
      izhs_total_area: territorySettings?.izhs_total_area || null,
      mkd_built_at: territorySettings?.mkd_built_at || null,
      mkd_floors: territorySettings?.mkd_floors || null,
      price_izhs: territorySettings?.price_izhs || null,
      price_oks_izhs: territorySettings?.price_oks_izhs || null,
      price_mkd: territorySettings?.price_mkd || null,
      gradpotential: territorySettings?.gradpotential || null,
    },
  });

  const Submit: SubmitHandler<LayersSettigsFormFields> = (formData) => {
    const numericData: any = {
      ...formData,
    };
    const email = data?.email.substring(0, data?.email.indexOf('@'));

    Object.keys(numericData).forEach((key) => {
      if (!isNaN(parseFloat(numericData[key]))) {
        numericData[key] = parseFloat(numericData[key]);
      }
    });

    setTerritorySettings({
      ...numericData,
      territoryId: territoryId,
      email: email,
    });
  };

  return (
    <Stack flexDirection={'column'} height={'100%'} flex={1}>
      <Header
        heading={
          <Stack flexDirection="row" justifyContent="space-between">
            <Typography variant="h6">Настройки</Typography>
            <IconButton onClick={handleCloseSideSheet}>
              <IconClose />
            </IconButton>
          </Stack>
        }
      />
      <Stack flexGrow={1} overflow={'auto'} padding={6}>
        {territorySettings?.version && (
          <Stack marginBottom={1} flexDirection={'row'} alignItems={'center'}>
            <Typography marginRight={1} fontSize={11}>
              Версия:
            </Typography>
            <Typography fontSize={11}>{territorySettings.version}</Typography>
          </Stack>
        )}
        <Stack gap={4}>
          <Stack gap={1}>
            <Typography fontWeight={500} fontSize={13}>
              Параметры критериальности
            </Typography>
            <Box>
              <Typography fontWeight={500} fontSize={11}>
                ИЖС
              </Typography>
              <Typography color={'#8A8C8C'} fontSize={11}>
                Общая площадь дома, м<sup>2</sup>
              </Typography>
              <Controller
                control={control}
                name="izhs_total_area"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    placeholder="Введите площадь"
                    fullWidth
                    type="number"
                    value={value ?? ''}
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <Typography marginTop={1} fontWeight={500} fontSize={11}>
                МКД
              </Typography>
              <Typography color={'#8A8C8C'} fontSize={11}>
                Год постройки
              </Typography>
              <Controller
                control={control}
                name="mkd_built_at"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    placeholder="Введите год"
                    fullWidth
                    // type="number"
                    value={value ?? ''}
                    onChange={(event) => {
                      const inputValue = event.target.value.replace(
                        /[^0-9]/g,
                        '',
                      );
                      onChange(inputValue);
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <Typography color={'#8A8C8C'} fontSize={11}>
                Этажность
              </Typography>
              <Controller
                control={control}
                name="mkd_floors"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    placeholder="Введите этажность"
                    fullWidth
                    // type="number"
                    value={value ?? ''}
                    onChange={(event) => {
                      const inputValue = event.target.value.replace(
                        /[^0-9]/g,
                        '',
                      );
                      onChange(inputValue);
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
          <Stack gap={1}>
            <Typography fontWeight={500} fontSize={13}>
              Ценовые параметры
            </Typography>
            <Box>
              <Typography color={'#8A8C8C'} fontSize={11}>
                м<sup>2</sup> участка ИЖС, руб.
              </Typography>
              <Controller
                control={control}
                name="price_izhs"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    placeholder="Введите цену"
                    fullWidth
                    type="number"
                    value={value ?? ''}
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <Typography color={'#8A8C8C'} fontSize={11}>
                м<sup>2</sup> ОКС ИЖС, руб.
              </Typography>
              <Controller
                control={control}
                name="price_oks_izhs"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    placeholder="Введите цену"
                    fullWidth
                    type="number"
                    value={value ?? ''}
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                  />
                )}
              />
            </Box>
            <Box>
              <Typography color={'#8A8C8C'} fontSize={11}>
                м<sup>2</sup> квартиры в МКД, руб.
              </Typography>
              <Controller
                control={control}
                name="price_mkd"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    placeholder="Введите цену"
                    fullWidth
                    type="number"
                    value={value ?? ''}
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
          <Stack gap={1}>
            <Typography fontWeight={500} fontSize={13}>
              Градпотенциал
            </Typography>
            <Box>
              <Typography color={'#8A8C8C'} fontSize={11}>
                Коэффициент плотности застройки
              </Typography>
              <Controller
                control={control}
                name="gradpotential"
                rules={{ required: true }}
                render={({ field: { value, onChange } }) => (
                  <TextField
                    placeholder="Введите коэффициент"
                    fullWidth
                    type="number"
                    value={value ?? ''}
                    onChange={(event) => {
                      onChange(event.target.value);
                    }}
                  />
                )}
              />
            </Box>
          </Stack>
        </Stack>
      </Stack>
      <Stack
        borderTop={1}
        borderColor={'rgba(20, 25, 26, 0.12)'}
        padding={3}
        gap={2}
        justifyContent={'space-between'}
        flexDirection={'row'}
      >
        <Button
          disabled={!isValid}
          onClick={handleSubmit(Submit)}
          variant="filled"
          children="Сохранить"
        />
        {/* <Button
          onClick={() => {
            reset();
          }}
          variant="outline"
          children="Сбросить"
        /> */}
      </Stack>
    </Stack>
  );
};

export default LayersSettigsSideSheet;
