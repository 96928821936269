import { IconClose } from '@brusnika.tech/ui-icons';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  ToggleButton,
  MultiCombobox,
  useDebouncedCallback,
  Select,
} from '@brusnika.tech/ui-kit';
import {
  PaddedContentContainer,
  SideSheet,
  useLayout,
} from '@brusnika.tech/ui-portal';
import {
  IconButton,
  Stack,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useAppDispatch } from '../../hooks';
import { setBuildingLayer } from '../store/slices/buildingLayerSlice';
import { useGetAllBuildingTypesQuery } from '../store/services/buildings';
import { ISelectElement } from '@brusnika.tech/ui-kit/types/ISelectElement';
import { isOptionEqualToValue } from '../../common/utils';
import NspdControl from '../layerManagment/NspdControl';
import MinGkhControl from '../layerManagment/MinGkhControl';
import { setMapStyle } from '../store/slices/mapSettingsSlice';
import { useSelector } from 'react-redux';
import { RootState } from '../store/store';
import GranicaNp from '../layerManagment/GranicaNp';
import EmergencyIsogd from '../layerManagment/EmergencyIsogd';
import OksOsm from '../layerManagment/OksOsm';
import ProjectDeclaration from '../layerManagment/ProjectDeclaration';
import NspdZoning from '../layerManagment/NspdZoning';
import KrtControl from '../layerManagment/KrtControl';
import IzhsControl from '../layerManagment/IzhsControl';
import {
  useGetAllTerritorySettingsVersionsQuery,
  useGetTerritorySettingsQuery,
} from '../store/services/layerSettings';
import { useParams } from 'react-router-dom';

const KrtRightSidebar = () => {
  const { toggleRightDrawer } = useLayout();
  const dispatch = useAppDispatch();
  const { id }: { id?: string } = useParams();
  const [selectedVersion, setSelectedVersion] = useState<any | null>(null);

  const onChangeVersionHandler = (
    _event: SyntheticEvent,
    value: any | null,
  ) => {
    setSelectedVersion(value);
  };

  const { data: territorySettingsVersions } =
    useGetAllTerritorySettingsVersionsQuery(
      { territoryId: id! },
      { skip: !id },
    );

  const { data: territorySettings } = useGetTerritorySettingsQuery(
    { territoryId: id! },
    { skip: !id },
  );

  useEffect(() => {
    setSelectedVersion(territorySettings || null);
  }, [territorySettings]);
  // const { data: buildings } = useGetAllBuildingTypesQuery();
  // const [buildingValues, setBuildingValues] = useState<ISelectElement[]>([]);

  // const buildingOptions = useMemo(
  //   () =>
  //     buildings?.map<ISelectElement>((employee) => ({
  //       label: employee.label,
  //       color: employee.color,
  //       id: employee.id,
  //       value: employee.value,
  //     })) ?? [],
  //   [buildings],
  // );

  // const debounced = useDebouncedCallback((value: any) => {
  //   dispatch(setBuildingLayer(value));
  // }, 500);

  const mapStyle = useSelector((state: RootState) => state.mapSetting.mapStyle);

  // const [value, setValue] = useState<string>('');
  const handleChangeValue = (
    _event: React.MouseEvent<HTMLElement>,
    newValue: string,
  ) => {
    // setValue(newValue);
    newValue !== null && dispatch(setMapStyle(newValue));
  };

  // const multiComboboxOpts = {
  //   placeholder: 'Выберите',
  // };

  return (
    <SideSheet
      // overlap
      header={
        <Stack flexDirection="row" justifyContent="space-between">
          <Typography variant="h6">Управление слоями</Typography>
          <IconButton
            onClick={() => {
              toggleRightDrawer();
              localStorage.setItem('layerController', 'false');
            }}
          >
            <IconClose />
          </IconButton>
        </Stack>
      }
    >
      <PaddedContentContainer>
        <>
          <ToggleButtonGroup
            value={mapStyle}
            onChange={handleChangeValue}
            exclusive
            fullWidth
            sx={{ marginBottom: 3 }}
          >
            <ToggleButton
              value={
                'mapbox://styles/andreev1057/clw4wqp7902op01qu3q15fta5/draft'
              }
              aria-label="left aligned"
            >
              Схема
            </ToggleButton>
            <ToggleButton
              value={'mapbox://styles/mapbox/satellite-v9'}
              aria-label="centered"
            >
              Спутник
            </ToggleButton>
            <ToggleButton value={''} aria-label="right aligned">
              Без карты
            </ToggleButton>
          </ToggleButtonGroup>
          {/* <RadioGroup sx={{ margin: '12px 0' }}>
            <FormControlLabel value={1} control={<Radio />} label={'Mapbox'} />
            <FormControlLabel value={2} control={<Radio />} label={'2ГИС'} />
          </RadioGroup> */}
          <GranicaNp />
          <NspdControl />
          <MinGkhControl />
          <IzhsControl />
          {/* <EmergencyIsogd /> */}
          <OksOsm />
          {selectedVersion && (
            <Stack marginTop={2}>
              <Typography fontWeight={500} fontSize={11}>
                Версии настроек
              </Typography>
              {selectedVersion && (
                <Select
                  fullWidth
                  value={selectedVersion}
                  onChange={onChangeVersionHandler}
                  isOptionEqualToValue={(option: any, value: any) =>
                    option.version === value.version
                  }
                  getOptionLabel={(option: any) => option.version}
                  options={territorySettingsVersions!}
                  // autoComplete
                />
              )}
            </Stack>
          )}
          {/* <ProjectDeclaration /> */}
          {/* <NspdZoning /> */}
          {/* /// Незастроенные территории*/}
          {/* <KrtControl /> */}
          {/* <MultiCombobox
            isOptionEqualToValue={isOptionEqualToValue}
            options={buildingOptions}
            useTooltip={false}
            value={buildingValues}
            onChange={(_, v) => {
              setBuildingValues(v);
              debounced(v);
            }}
            // {...multiComboboxOpts}
            sx={{ marginTop: 4, marginBottom: 4 }}
            noOptionsText="Не найдено"
            noTags
          /> */}
        </>
      </PaddedContentContainer>
    </SideSheet>
  );
};

export default KrtRightSidebar;
