import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { baseApi } from './services/baseApi';
import { territoryReducer } from './slices/territorySlice';
import { krtReducer } from './slices/krtSlice';
import { tradingInfoReducer } from './slices/tradingKrtInfo';
import { buildingLayerReducer } from './slices/buildingLayerSlice';
import { tableSettingsReducer } from './slices/tableSettingsSlice';
import { mapSettingsReducer } from './slices/mapSettingsSlice';
import { tileLayerReducer } from './slices/tileLayerSlice';
import { layerMetaInfoReducer } from './slices/layerMetaInfoSlice';
import { activeLayersManagerReducer } from './slices/activeLayersManager';

export const store = configureStore({
  reducer: {
    [baseApi.reducerPath]: baseApi.reducer,
    territory: territoryReducer,
    krt: krtReducer,
    tradingInfo: tradingInfoReducer,
    buildingLayer: buildingLayerReducer,
    tableSetting: tableSettingsReducer,
    mapSetting: mapSettingsReducer,
    tileLayer: tileLayerReducer,
    layerMetaInfo: layerMetaInfoReducer,
    activeLayers: activeLayersManagerReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseApi.middleware),
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
