import { createSlice } from '@reduxjs/toolkit';
import { TradingKrtInfo } from '../../../types/tradingKrtInfo';

const initialState: TradingKrtInfo = {
  auctionStatus: '',
  auctionSubject: '',
  finalPrice: '',
  geometry: undefined,
  krt: {
    id: '',
    code: '',
    territoryId: '',
    centroid: {
      type: '',
      coordinates: [],
    },
    geometry: {
      type: '',
      coordinates: [],
    },
  },
  krtArea: '',
  krtId: '',
  krtPeriod: '',
  krtUrl: '',
  lotDescription: '',
  lotStatus: '',
  noticesUrl: '',
  organizationName: '',
  penultimateSentence: '',
  platform: '',
  sequence: '',
  startPrice: '',
  startRequestsDate: '',
  subjectRF: '',
  summation: '',
  territoryId: '',
  winner: '',
  startAuctionDate: '',
};

const tradingInfoSlice = createSlice({
  name: 'tradingInfoSlice',
  initialState: initialState,
  reducers: {
    setTradingKrtInfo: (state, action) => {
      return action.payload;
    },
    resetTradingKrtInfo: () => {
      return initialState;
    },
  },
});

export const { setTradingKrtInfo, resetTradingKrtInfo } =
  tradingInfoSlice.actions;
export const { reducer: tradingInfoReducer } = tradingInfoSlice;
