import { ITab } from "@brusnika.tech/ui-portal";

export const projectConfig: ITab = {
  id: "rnd-monitor",
  title: "ИСУП Развития",
  path: "",
  noRedirect: true,
  children: [
    {
      id: "krt",
      title: "КРТ",
      path: "krt",
      children: [],
    },
    {
      id: "trading-monitoring",
      title: "Мониторинг торгов",
      path: "trading-monitoring",
      children: [],
    },
  ],
};
